import Vue from 'vue'
import Vuex from 'vuex'
import 'es6-promise/auto'

Vue.use(Vuex)

import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const store = new Vuex.Store({
	state: {
		needsEmailVerification: false,
		verified: null,
		user: {},
		role: null
	},
	getters,
	mutations,
	actions,
	strict: process.env.NODE_ENV !== 'production'
})

export default store