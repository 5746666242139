<template>
	<v-dialog width="500" v-model="modal">
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				outlined
				rounded
				color="accent"
				class="mt-4 mr-5 text-capitalize"
				v-bind="attrs"
				v-on="on"
			>Sign Out</v-btn>
		</template>
		<v-card class="rounded-lg signout-modal text-center px-15 py-10">
			<div class="text-h6 font-weight-black">Are you sure you want to sign out?</div>
			<hr class="divider my-5">
			<div class="d-flex justify-space-between px-10">
				<v-btn color="primary" outlined width="40%" v-on:click="signOut">Yes</v-btn>
				<v-btn color="accent" outlined width="40%" v-on:click="close">No</v-btn>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
import { logout } from './../js/firebase-functions'

export default {
	name: 'SignOutModal',
	data: function() {
		return {
			modal: false
		}
	},
	methods: {
		close() {
			this.modal = false
		},
		signOut() {
			logout()
		}
	}
}
</script>

<style lang="scss" scoped>
.signout-modal {
	border: 3px solid var(--v-info-base) !important;
}
</style>