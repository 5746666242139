import { user } from '@/router/guards'
const clientAndStoreManagerGuard = (to, from, next) => user(to, next, ['client', 'store-manager'])
const clientGuard = (to, from, next) => user(to, next, ['client'])

export default [
  {
    path: '/client/screening-compliance-reports',
    name: 'Screening Compliance Reports',
    component: () => import(/* webpackChunkName: "client" */ './views/ScreeningComplianceReports'),
    beforeEnter: clientAndStoreManagerGuard,
    meta: { title: 'Screening Compliance Reports' }
  },
  {
    path: '/client/employee-approvals-denials',
    name: 'Employee Approvals Denials',
    component: () => import(/* webpackChunkName: "client" */ './views/ApprovalsDenials'),
    beforeEnter: clientAndStoreManagerGuard,
    meta: { title: 'Employee Approvals and Denials' }
  },
  {
    path: '/client/add-employee',
    name: 'Add Employee',
    component: () => import(/* webpackChunkName: "client" */ './views/AddEmployee'),
    beforeEnter: clientAndStoreManagerGuard,
    meta: { title: 'Add Employee' }
  },
  {
    path: '/client/my-store-managers',
    name: 'My Store Managers',
    component: () => import(/* webpackChunkName: "client" */ './views/ListStoreManagers'),
    beforeEnter: clientGuard,
    meta: { title: 'My Store Managers' }
  }
]