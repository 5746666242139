import store from './index'

import router from '@/router/index'
import { auth } from '@/js/firebase/init'
import { getIdTokenResult, getIdToken } from 'firebase/auth'

export default {
	async auth({ commit, state }, data) {
		if (auth.currentUser !== null && (auth.currentUser.emailVerified || data.emailVerifiedAction)) {
			commit('setUser', {
				name: auth.currentUser.displayName,
				email: auth.currentUser.email,
				uid: auth.currentUser.uid
			})
			commit('setVerified', !!localStorage.getItem('verified'))
			commit('setRole', localStorage.getItem('role'))

			const token = await getIdTokenResult(auth.currentUser, data.refreshToken)
			const role = token.claims.role
			commit('setRole', role)
			if (role == 'store-manager') commit('setStoreNumber', token.claims.storeNumber)
			if (role != 'admin' && role != 'super-admin') commit('setClientNumber', token.claims.number)
			localStorage.setItem('role', role)

			if (!token.claims.needsPasswordReset) {
				commit('setVerified', true)
				localStorage.setItem('verified', true)
			} else {
				commit('setVerified', false) // just in case localStorage value was changed
				if (router.history.current.name != 'Reset Password' && !data.emailVerifiedAction) { 
					router.push({ name: 'Reset Password' })
				}
			}
			// need to refresh token for firebase requests
			if (data.emailChange) await getIdToken(auth.currentUser, true)
		} else {
			commit('setVerified', false)
			commit('setUser', null)
			commit('setRole', null)
			localStorage.removeItem('verified')
			localStorage.removeItem('role')
			localStorage.removeItem('client_name')
		}
	}
}