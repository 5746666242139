<template>
	<v-container fluid class="pa-0 ">
		<v-row v-for="i in numRows" :key="i">
			<v-col
				:cols="getNumCols(input)"
				:class="getColClass(input)"
				v-for="input in getInputsInRow(i)"
				:key="input.ref"
			>
				<v-divider class="divider" v-if="input.lineDivider"></v-divider>
				<v-btn
					color="secondary"
					v-if="input.expand"
					:ripple="false"
					class="mb-3 text-capitalize"
					@click="toggle(input)"
				>
					<v-icon class="pr-2">{{ input.expandIcon ? 'mdi-arrow-expand-down' : 'mdi-arrow-expand-up' }}</v-icon>
					{{ input.text }}
					<v-icon class="pl-2">{{ input.expandIcon ? 'mdi-arrow-expand-down' : 'mdi-arrow-expand-up' }}</v-icon>
				</v-btn>
				<div v-if="isInput(input)">
					<div class="text-h5 mb-5 text-no-wrap" v-if="input.rowHeader">{{ input.rowHeader }}</div>
					<v-text-field
						outlined
						color="selected"
						class="font-size"
						dense
					  :label="getInputLabel(input.label, input.required)"
					  :id="input.ref"
					  :type="input.type"
					  :placeholder="input.placeholder"
					  :maxlength="input.maxlength"
					  :pattern="input.pattern"
					  :required="input.required"
					  :rules="input.rules"
					  :hint="input.hint"
					  :prepend-inner-icon="input.prependInnerIcon"
					  persistent-hint
					  :validate-on-blur="!firstSubmit"
					  v-model="form[input.ref]"
					  v-if="input.type != 'select'&& checkExpand(input) && input.ref != 'ssn'"
					></v-text-field>
					<v-autocomplete
					  :items="input.options"
					  outlined
					  dense
					  color="selected"
					  class="font-size"
					  item-color="selected"
					  :label="getInputLabel(input.label, input.required)"
					  :required="input.required"
					  :rules="input.rules"
					  :menu-props="{ bottom: true,  offsetY: true, maxHeight: input.maxMenuHeight }"
					  :id="input.ref"
					  :loading="input.loading"
					  :disabled="input.disabled"
					  @input="input.change"
					  v-model="form[input.ref]"
					  v-if="input.type == 'select' && checkExpand(input)"
					></v-autocomplete>
					<ssn-input
						class="form-renderer-ssn"
						v-if="input.ref == 'ssn'"
						:rules="input.rules"
						:input="input"
						:validate-on-blur="!firstSubmit"
						v-on:update="updateSSN"
					></ssn-input>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import ssnInput from './SSN'
import { parsePhone } from '@/js/phone-number-validation'

export default {
	name: 'FormRenderer',
	props: {
		inputs: Array,
		inputPerRow: Number,
		inputCols: Number,
		firstSubmit: Boolean,
		finalSubmit: Number
	},
	components: {
		ssnInput
	},
	created: function() {
		if (this.inputs[0]) this.initialize()
	},
	data: function() {
		return {
			numRows: null,
			defaults: {
				inputsPerRow: 3,
				inputCols: 4
			},
			expandParts: {},
			form: {}
		}
	},
	computed: {
		compInputs() {
			if (this.inputs) return this.inputs
			return []
		},
		compInputsPerRow() {
			if (this.inputsPerRow) return this.inputsPerRow
			return this.defaults.inputsPerRow
		},
		compInputCols() {
			if (this.inputCols) return this.inputCols
			return this.defaults.inputCols
		}
	},
	watch: {
		finalSubmit() {
			for (const key in this.form) {
				if (key.includes('phone')) this.form[key] = parsePhone(this.form[key])
			}
			this.$emit('form-submit', this.form)
		},
		inputs() {
			this.initialize()
		}
	},
	methods: {
		initialize() {
			this.numRows = Math.ceil(this.compInputs.length / this.compInputsPerRow)
			for (const i in this.inputs) {
				if (this.inputs[i].expand) {
					this.expandParts[this.inputs[i].ref] = i
					// true for down and false for up
					this.inputs[i].expandIcon = true
				}
			}
		},
		isInput(input) {
			return !input.spacer && !input.lineDivider && !input.expand
		},
		getNumCols(input) {
			return input.lineDivider ? '12' : this.compInputCols
		},
		getColClass(input) {
			return input.hasRowHeader ? 'col-w-row-header pb-0' : (!input.lineDivider ? 'pb-0' : 'py-2')
		},
		getInputsInRow(i) {
			return this.compInputs.slice((i-1)*3, (i-1)*3+3)
		},
		getInputLabel(label, required) {
			if (required) return `${label} *`
			return label
		},
		toggle(input) {
			input.show.fn()
			input.expandIcon = !input.expandIcon
		},
		checkExpand(input) {
			if (!this.expandParts[input.expandRef]) return true
			return this.inputs[this.expandParts[input.expandRef]].show.val
		},
		updateSSN(val) {
			this.form.ssn = val
		}
	}
}
</script>

<style lang="scss" scoped>
.font-size {
	font-size: 14px;
}
.col-w-row-header {
	margin-top: calc(44px + 0.5em);
}
.row:not(:first-child) {
	margin-top: 5px;
}
</style>