const storeManager = [
	{
		title: 'Screening Compliance Reports',
		subtitle: 'Reports',
		text: 'View screening compliance reports with option to export.',
		path: { name: 'Screening Compliance Reports' },
		btnText: 'View',
		btnColor: 'secondary'
	},
	{
		title: 'Employee Approvals and Denials',
		subtitle: 'Reports',
		text: 'Veiw employee approvals and denials with option to export.',
		path: { name: 'Employee Approvals Denials' },
		btnText: 'View',
		btnColor: 'secondary'
	},
	{
		title: 'Add New Employees',
		subtitle: 'Forms',
		text: 'Add a new employees to screen. Reminder email will be sent to employees.',
		path: { name: 'Add Employee' },
		btnText: 'Go',
		btnColor: 'info'
	}
]

const addToClient = [
	{
		title: 'Add Store Managers',
		subtitle: 'Forms',
		text: 'Add store managers who can add employees and view store reports.',
		path: { name: 'Add Users', params: { role: 'client', canAdd: 'store-managers' } },
		btnText: 'Go',
		btnColor: 'info'
	},
	{
		title: 'My Store Managers',
		subtitle: 'Reports',
		text: 'View your store managers. More features like reminder emails coming soon.',
		path: { name: 'My Store Managers' },
		btnText: 'View',
		btnColor: 'secondary'
	}
]

const coreAdmin = [
	{
		title: 'Review Screening Forms',
		subtitle: 'Forms',
		text: 'View, edit, and approve tax credit screening forms.',
		path: { name: 'Review Forms' },
		btnText: 'View',
		btnColor: 'secondary'
	},
	{
		title: 'Add Client Stores',
		subtitle: 'Forms',
		text: 'Add new clients with store locations.',
		path: { name: 'Add Client Stores' },
		btnText: 'Go',
		btnColor: 'info'
	}
]
const adminAddUsers = [{
	title: 'Add New Users',
	subtitle: 'Forms',
	text: 'Add clients and store managers to the portal.',
	path: { name: 'Add Users', params: { role: 'admin', canAdd: 'clients_store-managers' } },
	btnText: 'Go',
	btnColor: 'info'
}]
const superAdminAdminAddUsers = [{
	title: 'Add New Users',
	subtitle: 'Forms',
	text: 'Add admins, clients, and store managers to the portal.',
	path: { name: 'Add Users', params: { role: 'super-admin', canAdd: 'all' } },
	btnText: 'Go',
	btnColor: 'info'
}]


export default {
	'store-manager': storeManager,
	client: storeManager.concat(addToClient),
	admin: coreAdmin.concat(adminAddUsers),
	'super-admin': coreAdmin.concat(superAdminAdminAddUsers)
}