<template>
	<v-form ref="mobileSubmit">
		<div class="text-h6">{{ text.closing.title }}</div>
		<p class="text-body-2 font-italic mt-3">{{ text.closing.main }}</p>
		<p class="text-subtitle-2 font-weight-medium mt-7">{{ text.closing.inputHeader }}</p>
		<label class="required">{{ text.closing.ssn }}</label>
		<v-text-field
			class="mt-n3 mb-3 mobile-submit-field"
			color="selected"
			dense
			maxlength="4"
			:rules="ssnRule"
			v-model="signature.ssn"
		></v-text-field>
		<label>{{ text.closing.date }}</label>
		<v-text-field
			class="mt-n3 mobile-submit-field"
			color="selected"
			dense
      type="date"
			:maxlength="10"
			v-model="signature.date"
		></v-text-field>
		<progress-buttons
			:stepper="null"
			:numSteps="null"
			:disabled="false"
			:submit="true"
			:loading="loading"
			:text="text"
			v-on:previous="moveStepperBack()"
			v-on:next="submit()"
		></progress-buttons>
    <div class="continue-down submit-step text-right">
      <scroll-down-arrow :showScrollArrow="showScrollArrow" :toRight="true"></scroll-down-arrow>
    </div>
		<div v-if="clientName">
			<span style="font-size: 14px"><i>{{ text.record }}</i></span>
			<div class="font-italic mt-5 mb-2">{{ text.infoText }} <b>{{ clientName }}</b></div>
		</div>
	</v-form>
</template>

<script>
import progressButtons from './MobileProgressButtons'
import scrollDownArrow from './ScrollDownArrow.vue'
import showScrollArrowFunction from './../js/scroll-arrow'

export default {
	name: 'MobileSubmit',
	props: {
		text: Object,
		signatureSsnRules: Array,
		loading: Boolean,
		clientName: String
	},
	components: {
		progressButtons,
    scrollDownArrow
	},
  mounted: function() {
    showScrollArrowFunction('mobile-submit', this, 'showScrollArrow')
  },
	data: function() {
		return {
			signature: {
				ssn: null,
				date: null
			},
			ssnRule: [],
      showScrollArrow: true
		}
	},
	methods: {
		moveStepperBack() {
			this.$emit('moveStepperBack')
		},
		submit() {
			this.ssnRule = this.signatureSsnRules
			this.$nextTick(() => {
				const valid = this.$refs.mobileSubmit.validate()
				if (valid) this.$emit('submit', this.signature)
			})
		}
	}
}
</script>