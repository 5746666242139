<template>
	<info-page>
		<template v-slot:title>
			<span class="text-h4 text-decoration-underline">About Us</span>
			<p class="mt-10 page-info-text">Coming soon. . .</p>
		</template>
	</info-page>
</template>

<script>
import infoPage from './../components/InfoPage'

export default {
	name: 'AboutUs',
	components: {
		infoPage
	}
}
</script>