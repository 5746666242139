import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
	theme: {
    themes: {
      light: {
        primary: colors.blueGrey.lighten1,
        secondary: '#033466',
        accent: colors.grey.darken3,
        info: '#92cf51',
        selected: colors.blue.darken2,
        success: colors.green.lighten2,
        error: colors.red.lighten2
      }
    },
    options: {
      customProperties: true
    }
  }
})
