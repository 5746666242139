import store from '@/store/index'

function user(to, next, roles) {
	const auth = getAuthStoreVals()
	const roleCanGo = roles.indexOf(auth.role) != -1
	if (!roleCanGo) return next({ name: 'Not Authorized' })
	if (!auth.verified && to.name != 'Reset Password') return next({ name: 'Reset Password' })
	next()
}

function resetPassword(to, from, next) {
	const auth = getAuthStoreVals()
	if (auth.role) return next()
	actionCode(to, from, next)
}

function actionCode(to, from, next) {
	if (!to.query.oobCode) return next({ name: 'Not Authorized' })
	next()
}

function login(to, from, next) {
	const auth = getAuthStoreVals()
	if (!auth.role) return next()
	if (!auth.verified) return next({ name: 'Reset Password' })
	next({ name: 'Portal Home' })
}

function getAuthStoreVals() {
	const verified = store.getters.verified !== null ? store.getters.verified : localStorage.getItem('verified')
	const role = store.getters.role !== null ? store.getters.role : localStorage.getItem('role')

	return { verified, role }
}

export { user, login, resetPassword, actionCode }