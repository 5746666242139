
import { auth } from '@/js/firebase/init'
import { signInWithEmailAndPassword, getIdTokenResult, applyActionCode, verifyPasswordResetCode, confirmPasswordReset, sendPasswordResetEmail, updatePassword, signOut, updateProfile, updateEmail } from 'firebase/auth'
import router from '@/router/index'

function login(email, password) {
	return signInWithEmailAndPassword(auth, email, password)
}
function getIdToken() {
	return getIdTokenResult(auth.currentUser, false)
}
function actionCode(actionCode) {
	return applyActionCode(auth, actionCode)
}
function verifyPassResetCode(actionCode) {
	return verifyPasswordResetCode(auth, actionCode)
}
function confirmPassReset(actionCode, newPassword) {
	return confirmPasswordReset(auth, actionCode, newPassword)
}
function changePassword(newPassword) {
	return updatePassword(auth.currentUser, newPassword)
}
function logout() {
	signOut(auth)
	if (router.history.current.name != 'Home') router.push({ name: 'Home' })
}
function updateUser(updates) {
	return updateProfile(auth.currentUser, updates)
}
function newEmail(email) {
	return updateEmail(auth.currentUser, email)
}
function updateStoreManager(uid, data) {
  const ref = doc(firestore, 'store_managers', uid)
  return updateDoc(ref, data)
}


export { login, getIdToken, actionCode, verifyPassResetCode, confirmPassReset, changePassword, logout, updateUser, newEmail, updateStoreManager }
