export default function(e) {
  if (!e.dob || !e.state) return false
  if (e.state != 'New York') return false
  // check if age is between 16 and 40
  const convertToDays = 1000 * 60 * 60 * 24
  const today = (new Date()).getTime() / convertToDays
  let dobDate
  dobDate = new Date(e.dob)
  if (dobDate == 'Invalid Date') {
    // safari
    const dobArr = e.dob.split('-')
    dobDate = new Date(`${dobArr[2]}-${dobArr[0]}-${dobArr[1]}`)
  }
  dobDate = dobDate.getTime() / convertToDays
  const diff = (today - dobDate) / 365.25
  if (diff >= 16 && diff <= 40) return true
  return false
}