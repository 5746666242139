<template>
	<div class="ssn-div">
		<v-text-field
		  color="selected"
		  outlined
		  dense
		  :validate-on-blur="validateOnBlur"
		  :type="input.type"
		  :id="input.id"
		  :label="input.label"
		  :rules="rules"
		  :maxlength="input.maxlength"
		  :placeholder="input.placeholder"
		  validate-on-blur
		  v-model="ssn"
		  class="ssn-input"
		  @input="update"
      :ref="input.ref"
		>
			<template v-slot:append>
				<v-icon v-show="!ssnShow" @click="showSsnDots(true, false)" style="margin-top: -4px">mdi-eye</v-icon>
				<v-icon v-show="ssnShow" @click="showSsnDots(false, false)" style="margin-top: -4px">mdi-eye-off</v-icon>
			</template>
		</v-text-field>
		<v-icon v-for="(show, i) in ssnDots" class="ssn-dot" :style="`margin-left: ${calcSsnDotMargin(i)}px;`" v-show="show" :key="i">
			mdi-checkbox-blank-circle
		</v-icon>
	</div>
</template>

<script>
export default {
	name: 'SSN',
	props: {
		input: Object,
		rules: Array,
		validateOnBlur: Boolean,
    focus: Number // will watch this variable and every time it changes, will focus input
	},
	data: function() {
		return {
			ssn: null,
			ssnShow: false,
			ssnDots: new Array(6).fill(false),
		}
	},
	watch: {
		ssn(newVal, oldVal) {
			if (newVal == null) newVal = ''
			if (newVal.length < (oldVal ? oldVal.length : 0)) return this.showSsnDots(this.ssnShow, true)
			if (newVal.length == 3 || newVal.length == 6) this.ssn += '-'
			if ((newVal.length == 4 || newVal.length == 7) && newVal.charAt(newVal.length - 1) != '-') this.ssn = newVal.substring(0, newVal.length - 1) + '-' + newVal.charAt(newVal.length - 1)
			this.showSsnDots(this.ssnShow, true)
		},
    focus() {
      setTimeout(() => {
        try {
          this.$refs[this.input.ref].$refs.input.focus()
        } catch (err) { }
      }, 150)
    }
	},
	methods: {
		showSsnDots(ssnShow, wait) {
			this.ssnShow = ssnShow
			if (this.ssn == null) this.ssn = ''
			for (let x = 0; x < 6; x++) {
				if (!this.ssn.charAt(x) || this.ssnShow) {
					this.$set(this.ssnDots, x, false)
					continue
				}
				if (this.ssn.charAt(x) != '-') {
					if (!this.ssnDots[x]) {
						if (wait) {
							setTimeout(() => this.$set(this.ssnDots, x, true), 250)
						} else {
							this.$set(this.ssnDots, x, true)
						}
					} else {
						this.$set(this.ssnDots, x, true)
					}
				}
			}
		},
		calcSsnDotMargin(n) {
			if (!this.ssn) return
			let margin = (n+1)*15
			if (n <= 2) return margin - 1.5
			if (n <= 5) return margin - 5
			return margin
		},
		update() {
			this.$emit('update', this.ssn)
		}
	}
}
</script>