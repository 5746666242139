<template>
	<v-card elevation="4" class="rounded-lg pa-2">
		<v-card-title class="text-capitalize">{{ title }}</v-card-title>
		<v-card-subtitle>{{ subtitle }}</v-card-subtitle>
		<v-card-text>{{ text }}</v-card-text>
		<v-card-actions v-if="btn">
		  <v-btn depressed :color="btn.color" :to="btnLink">{{ btn.text }}</v-btn>
		</v-card-actions>
		<v-card-actions class="d-block" v-if="formtype">
		  <v-btn class="px-5" depressed color="secondary" :to="btnLink[0]">English</v-btn>
		  <br class="d-sm-none">
		  <v-btn class="px-5 ml-sm-5 mt-3 mt-sm-0" depressed color="info" :to="btnLink[1]">Español</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	name: 'HomeCard',
	props: {
		title: String,
		subtitle: String,
		text: String,
    formtype: String,
		extended: Boolean,
		btn: Object
	},
	computed: {
		btnLink: function() {
			if (this.formtype) {
				const baseLink = `/access?extended=${this.extended}`
				return [`${baseLink}&lang=en`, `${baseLink}&lang=sp`]
			}
			return this.btn.path
		}
	}
}
</script>