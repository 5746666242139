import Home from './views/Home'
import About from './views/About'
import Contact from './views/Contact'
import NotAuthorized from './views/NotAuthorized'
import NotFound from './views/NotFound'

export default [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: { title: 'Hudson Group Tax Credit Screening' }
	},
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: { title: 'About Us' }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: { title: 'Contact' }
  },
  {
    path: '/not-authorized',
    name: 'Not Authorized',
    component: NotAuthorized,
    meta: { title: 'Not Authorized' }
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
    meta: { title: 'Not Found' }
  }
]