<template>
	<div class="d-flex justify-center flex-column mobile-date-picker">
		<div class="d-flex align-center justify-space-between">
			<label>{{ text.date.year }}</label>
			<v-text-field
				v-model="date.year"
				:rules="yearRule"
				dense
				placeholder="yyyy"
				color="selected"
				:maxlength="4"
        validate-on-blur
			></v-text-field>
		</div>
		<div class="d-flex align-center justify-space-between">
			<label>{{ text.date.month }}</label>
			<v-text-field
				v-model="date.month"
				:rules="required"
				dense
				placeholder="mm"
				color="selected"
				:maxlength="2"
        validate-on-blur
			></v-text-field>
		</div>
		<div class="d-flex align-center justify-space-between">
			<label>{{ text.date.day }}</label>
			<v-text-field
				v-model="date.day"
				:rules="required"
				dense
				placeholder="dd"
				color="selected"
				:maxlength="2"
        validate-on-blur
			></v-text-field>
		</div>
	</div>
</template>

<script>
import { form as formText } from './../js/text'

export default {
  name: 'DatePickerMobile',
  props: {
    rules: Array,
    lang: String,
    reset: Boolean
  },
  data: function() {
    return {
      date: {
        year: null,
        month: null,
        day: null
      }
    }
  },
  computed: {
    yearRule() {
      if (!this.rules) return []
      return [v => !!v ? v.length == 4 || 'invalid year' : 'required']
    },
    required() {
      if (!this.rules) return []
      return [v => !!v || 'required']
    },
    text() {
      return formText[this.lang]
    }
  },
  watch: {
    date: {
      handler(date) {
        if (!date.year || !date.month || !date.day) return this.$emit('new-date', null)
        const formatDate = `${date.year}-${date.month.padStart(2, '0')}-${date.day.padStart(2, '0')}`
        this.$emit('new-date', formatDate)
      },
      deep: true
    },
    reset() {
      this.date = {
        year: null,
        month: null,
        day: null
      }
    }
  }
}
</script>