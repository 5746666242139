import { reqWithAuth as apiReq } from '@/js/portal-api'

const redirectURL = process.env.VUE_APP_BASE_URL + '/login'

function newStoreManager(user, clientNumber, clientName, storeNumber) {
	return apiReq('/new-user/store-manager', 'post', {
		data: { user, clientNumber, clientName, storeNumber, redirectURL }
	})
}

function newClient(user, clientNumber, clientName) {
	return apiReq('/new-user/client', 'post', {
		data: { user, clientNumber, clientName, redirectURL }
	})
}

function newAdmin(user) {
	return apiReq('/new-user/admin', 'post', {
		data: { user, redirectURL }
	})
}

export { newStoreManager, newClient, newAdmin }