<template>
	<v-container fluid class="logged-in-wrapper">
	  <page-title :title="title"></page-title>
	  <v-card class="rounded-lg px-15 py-10 mb-10">
	  	<div class="text-h5 mb-3" v-if="showUserType">User Type</div>
	  	<custom-select
	  		v-if="showUserType"
	  		id="user-type"
	  		:data="userTypes"
	  		flat
	  		dense
	  		background-color="secondary"
	  		class="user-type"
	  		@update="updateUserType"
	  	></custom-select>
	  	<v-form ref="addUser" @submit="validateForm" @submit.prevent>
	  		<form-renderer
					:inputs="formInputs"
					:first-submit="firstSubmit"
					:final-submit="finalSubmit"
					@form-submit="addUser"
				></form-renderer>
				<v-btn
					type="submit"
					color="success"
					class=" px-10 mt-5"
					:loading="loading"
					v-show="!!userType"
				>
					Add
				</v-btn>
				<v-alert
					type="success"
					transition="scroll-y-transition"
					class="text-center mt-7"
					elevation="3"
					:value="!!submittedEmail"
				>
					<span class="text-capitalize">{{ userTypeLabel }}</span> added! An email has been sent to <span class="font-italic blue--text">{{ submittedEmail }}</span>&nbsp; with login information
				</v-alert>
				<v-alert
					type="error"
					transition="scroll-y-transition"
					class="text-center mt-7"
					elevation="3"
					:value="error"
				>
					An error occured{{ !!errInfo ? '.' : '' }} {{ errInfo }}
				</v-alert>
	  	</v-form>
	  </v-card>
	  <v-snackbar v-model="errSnackbar" color="error">
			<div class="snackbar">Fix form errors</div>
    </v-snackbar>
	</v-container>
</template>

<script>
import pageTitle from './../components/PageTitle'
import formRenderer from './../components/FormRenderer'
import formInputs from './../js/new-user-inputs'
import customSelect from './../components/CustomSelect'
import { newStoreManager, newClient, newAdmin } from './../js/api'
import { getClientName, addStoreManager } from './../js/firebase-functions'
import { mapGetters } from 'vuex'

export default {
	name: 'NewUser',
	components: {
		pageTitle,
		formRenderer,
		customSelect
	},
	created: function() {
		if (this.role) {
			this.initialize()
		} else {
			const unsubscribe = this.$store.subscribe(({ type }) => {
		    if (type == 'setRole') {
		      this.initialize()
		      unsubscribe()
		    }
		  })
		}
	},
	data: function() {
		return {
			userTypes: [],
			userType: null,
			formInputsClass: null,
			formInputs: [],
			clientsLoading: true,
			storesLoading: true,
			loading: false,
			firstSubmit: false,
			finalSubmit: 0,
			error: false,
			errInfo: null,
			submittedEmail: null,
			errSnackbar: false
		}
	},
	computed: {
		...mapGetters(['role', 'user']),
		title() {
			if (this.$route.params.role == 'admin' || this.role == 'super-admin') {
				return 'Add Users'
			} else {
				return 'Add Store Managers'
			}
		},
		userTypeLabel() {
			return this.userType ? this.userType.split('-').join(' ') : null
		},
		showUserType() {
			return this.role && this.role != 'client'
		}
	},
	methods: {
		initialize() {
			this.formInputsClass = new formInputs(this.role)
			if (this.role == 'super-admin') {
				this.userTypes = ['admin', 'client', {
					text: 'store manager',
					value: 'store-manager'
				}]
			} else if (this.role == 'admin') {
				this.userTypes = ['client', {
					text: 'store manager',
					value: 'store-manager'
				}]
			} else {
				this.userTypes = [{
					text: 'store manager',
					value: 'store-manager'
				}]
				this.updateUserType('store-manager')
			}
		},
		updateUserType(type) {
			if (!!this.userType) this.$refs.addUser.reset()
			this.userType = type
			this.formInputs = this.formInputsClass.inputs[type]
			if (type == 'client') {
				this.formInputsClass.setClients(type)
			} // adding store-manager
			else if (this.role == 'client') {
				if (this.user.clientNumber) return this.formInputsClass.setStores(this.user.clientNumber)
				const unsubscribe = this.$store.subscribe(({ type }) => {
			    if (type == 'setClientNumber') {
			      this.formInputsClass.setStores(this.user.clientNumber)
			      unsubscribe()
			    }
			  })
			} else {
				this.formInputsClass.setClients(type)
			}
		},
		getStores(number) {
			getStores(number).then(stores => {
				this.clientStores = stores
				if (this.clientStores.length == 1) this.selectedStore = this.clientStores[0]
				this.storesLoading = false
			})
		},
		validateForm() {
			this.firstSubmit = true
			this.submittedEmail = null
			const valid = this.$refs.addUser.validate() //&& !this.noStoreSelected
			if (!valid) return this.errSnackbar = true
			this.loading = true
			this.error = false
			this.finalSubmit++
		},
		async addUser(data) {
			function firebaseUser(data) {
				return {
					displayName: `${data.first_name} ${data.last_name}`,
					email: data.email
				}
			}

			if (this.userType == 'store-manager') {
				let client
				if (this.role == 'client') {
					name = await getClientName()
					client = { name, number: this.user.clientNumber }
				} else {
					client = data.client_info
				}
				const user = firebaseUser(data)
				const store_number = data.store_number
				this.handleRequest(newStoreManager(user, client.number, client.name, data.store_number), data.email).then(uid => addStoreManager(client.number, store_number, user.displayName, user.email, uid))
			} else if (this.userType == 'client') {
				this.handleRequest(newClient(firebaseUser(data), data.client_info.number, data.client_info.name), data.email)
			} else {
				this.handleRequest(newAdmin(firebaseUser(data)), data.email)
			}
		},
		handleRequest(promise, email) {
			return new Promise(resolve => {
				promise.then(res => {
					resolve(res.data)
					this.loading = false
					this.submittedEmail = email
					this.$refs.addUser.reset()
				}).catch(err => {
					this.errInfo = null
					this.loading = false
					this.error = true
					if (err.response.data && err.response.data.details && err.response.data.details.code == 'auth/email-already-exists') this.errInfo = 'A user with the email address already exists'
				})
			})
		}
	}
}
</script>

<style>
.user-type {
	min-width:  200px;
	max-width:  250px;
}
</style>