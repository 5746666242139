<template>
	<div class="home-wrapper px-3 pt-3 d-flex flex-column hudson-home">
		<div class="pa-5 d-flex flex-column flex-md-row justify-center justify-md-start">
			<!-- <div class="d-flex justify-center mb-10 mb-md-0 z-index">
				<card
					title="Tax Credit Screening"
					subtitle="Employee Form"
					text="Use your client number to find your store and fill out the form."
					formtype="tax_credit_screening"
				></card>
			</div>
			<div class="spacer" v-if="$vuetify.breakpoint.mdAndUp"></div> -->
			<!-- <div class="d-flex justify-center mb-10 mb-md-0 z-index" v-if="$vuetify.breakpoint.mdAndUp">
				<card
					:title="loginTitle"
					:subtitle="loginSubtitle"
					:text="loginText"
					:btn="loginBtn"
				></card>
			</div> -->
      <div class="d-flex justify-center mb-10 mb-md-0 z-index">
				<card
					title="Tax Credit Screening"
					subtitle="Employee Form"
					text="Use your client number to find your store and fill out the extended form."
					formtype="tax_credit_screening"
          :extended="true"
				></card>
			</div>
			<div class="spacer" v-if="$vuetify.breakpoint.mdAndUp"></div>
			<div class="d-flex justify-center mb-10 mb-md-0 z-index">
				<card
					title="Hudson Group"
					subtitle="About Us"
					text="Learn about what we do and who our clients are."
					:btn="{ path: { name: 'About' }, text: 'Learn more', color: 'secondary' }"
				></card>
			</div>
			<div class="spacer" v-if="$vuetify.breakpoint.mdAndUp"></div>
			<div class="d-flex justify-center mb-10 mb-md-0 z-index">
				<card
					title="Contact"
					subtitle="Get in Touch"
					text="Have a question? Email or call our customer representative."
					:btn="{ path: { name: 'Contact' }, text: 'Learn more', color: 'success' }"
				></card>
			</div>
		</div>
		<div class="mobile-display-block rounded-t-xl mx-n6 d-md-none"></div>
		<div class="home-info-div mx-n6 rounded-t-xl text-center d-none d-md-block"></div>
	</div>
</template>

<script>
import card from './../components/HomeCard'
import { mapGetters } from 'vuex'

export default {
	name: 'Home',
	components: {
		card
	},
	computed: {
		...mapGetters(['verified', 'role']),
		roleLabel() {
			return this.role.split('-').join(' ')
		},
		loginTitle() {
			if (this.verified) return `${this.roleLabel} views`
			if (this.role) return 'reset password'
			return 'log in'
		},
		loginText() {
			if (!this.verified && !this.role) return 'We offer additional services and views to our clients.'
			if (!this.verified) return 'Before proceeding, you must reset your password.'
			if (this.role == 'client' || this.role == 'store-manager') return 'View employee reports and add store managers.'
			return 'Add client stores and users. View tax credit screening reports.'
		},
		loginSubtitle() {
			if (!this.verified || this.role == 'client') return 'For clients'
			return 'For ' + this.role.split('-').join(' ') + 's'
		},
		loginBtn() {
			if (this.verified) return { path: { path: `${this.role}/views` }, text: 'Go', color: 'secondary' }
			if (this.role) return { path: { name: 'Reset Password' }, text: 'Go', color: 'secondary' }
			return { path: { name: 'Login' }, text: 'login', color: 'secondary' }
		}
	}
}
</script>