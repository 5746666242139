import { getAllClients, getStores } from './firebase-functions'

const rules = {
	required: v => !!v || 'required',
	email: v => !!v ? (/^\S+@\S+\.\S+$/.test(v) || 'invalid email') : true
}

const inputs = {
	admin: [
		{
			rowHeader: 'Name/ Contact',
			label: 'First Name',
			required: true,
			rules: [rules.required],
			type: 'text',
			ref: 'first_name'
		},
		{
			hasRowHeader: true,
			label: 'Last Name',
			required: true,
			rules: [rules.required],
			type: 'text',
			ref: 'last_name'
		},
		{
			hasRowHeader: true,
			label: 'Email Address',
			required: true,
			type: 'email',
			rules: [rules.required, rules.email],
			ref: 'email'
		}
	],
	client: [
		{
			rowHeader: 'Name/ Contact',
			label: 'First Name',
			required: true,
			rules: [rules.required],
			type: 'text',
			ref: 'first_name'
		},
		{
			hasRowHeader: true,
			label: 'Last Name',
			required: true,
			rules: [rules.required],
			type: 'text',
			ref: 'last_name'
		},
		{
			hasRowHeader: true,
			label: 'Email Address',
			required: true,
			type: 'email',
			rules: [rules.required, rules.email],
			ref: 'email'
		},
		{
			rowHeader: 'Client Info',
			label: 'Client Name',
			required: true,
			options: [],
			loading: true,
			rules: [rules.required],
			maxMenuHeight: '175px',
			type: 'select',
			ref: 'client_info'
		},
	],
	'store-manager': [
		{
			rowHeader: 'Name/ Contact',
			label: 'First Name',
			required: true,
			rules: [rules.required],
			type: 'text',
			ref: 'first_name'
		},
		{
			hasRowHeader: true,
			label: 'Last Name',
			required: true,
			rules: [rules.required],
			type: 'text',
			ref: 'last_name'
		},
		{
			hasRowHeader: true,
			label: 'Email Address',
			required: true,
			type: 'email',
			rules: [rules.required, rules.email],
			ref: 'email'
		}
	]
}

export default class {
	constructor(role) {
		this.role = role
		this.inputs = Object.assign({}, inputs)
		if (role == 'client') {
			this.inputs['store-manager'] = inputs['store-manager'].concat([{
				rowHeader: 'Store Info',
				label: 'Store Number',
				required: true,
				options: [],
				loading: true,
				rules: [rules.required],
				type: 'select',
				ref: 'store_number'
			}])
		} else {
			this.inputs['store-manager'] = inputs['store-manager'].concat([
				{
					rowHeader: 'Store Info',
					label: 'Client Name',
					required: true,
					options: [],
					loading: true,
					change: val => {
						if (val) this.setStores(val.number)
					},
					rules: [rules.required],
					maxMenuHeight: '175px',
					type: 'select',
					ref: 'client_info'
				},
				{
					hasRowHeader: true,
					label: 'Store Number',
					required: true,
					options: [],
					loading: false,
					disabled: true,
					maxMenuHeight: '175px',
					rules: [rules.required],
					type: 'select',
					ref: 'store_number'
				}
			])
		}
	}
	
	setClients(roleToAdd) {
		const input = this.inputs[roleToAdd][3]
		input.loading = true
		getAllClients().then(querySnapshot => {
			const clients = []
			querySnapshot.forEach(doc => {
				clients.push({
					text: `${doc.data().client_name}, ${doc.id}`,
					value: {
						name: doc.data().client_name,
						number: doc.id
					}
				})
			})
			input.options = clients
			input.loading = false
		})
	}
	setStores(client_number) {
		const index = this.role == 'client' ? 3 : 4
		const input = this.inputs['store-manager'][index]
		input.loading = true
		getStores(client_number).then(stores => {
			input.disabled = false
			input.options = stores
			input.loading = false
		})
	}
}