<template>
	<div>
		<v-row>
			<v-col :cols="!mobile ? '7' : '12'" :offset="!mobile ? '1' : null">
				<div class="text-h6 text-md-h5 mb-5 mb-md-0">{{ text.title }}</div>
				<p class="font-italic mt-2 text-body-2 text-md-body-1">{{ text.main }}</p>
			</v-col>
		</v-row>
		<v-row class="mt-n3">
			<v-col :cols="!mobile ? '8' : '12'" :offset="!mobile ? '1' : null">
				<ul class="yq-list text-body-2 text-md-body-1">
					<li>{{ text.bullets[0] }}</li>
					<li>{{ text.bullets[1] }}</li>
					<li>{{ text.bullets[2] }}</li>
					<li>{{ text.bullets[3] }}</li>
					<li>{{ text.bullets[4] }}</li>
					<li>{{ text.bullets[5] }}</li>
					<li>{{ text.bullets[6] }}</li>
					<li>{{ text.bullets[7] }}</li>
					<li>{{ text.bullets[8] }}</li>
					<li>{{ text.bullets[9] }}</li>
					<li>{{ text.bullets[10] }}</li>
					<li>{{ text.bullets[11] }}</li>
					<li>{{ text.bullets[12] }}</li>
					<li>{{ text.bullets[13] }}</li>
					<li><span>{{ text.bullets[14] }}</span></li>
				</ul>
				<div class="mt-5 ml-6">
					<span class="mr-4">{{ text.no }}</span>
					<v-switch
						color="success"
						class="d-inline-block"
						style="position: relative; top: 3px;"
						inset
						:ripple="false"
						v-model="checked"
					></v-switch>
					<span>{{ text.yes }}</span>
				</div>
			</v-col>
		</v-row>
		<v-row v-if="!mobile">
	  	<v-col cols="12">
	  		<hr class="divider">
	  	</v-col>
	  </v-row>
	</div>
</template>

<script>
import { youthQuestionnaire } from './../js/text'

export default {
	name: 'YouthQuestionnare',
	props: {
		lang: String,
		mobile: Boolean
	},
	data: function() {
		return {
			checked: true
		}
	},
  computed: {
    text() {
      return youthQuestionnaire[this.lang]
    }
  },
	watch: {
		checked: function(val) {
			this.$emit('checked', val)
		},
	}
}
</script>