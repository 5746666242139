<template>
	<div class="d-flex align-center justify-center card-center-wrapper">
		<v-card elevation="5" class="d-flex align-center justify-center rounded-lg pa-2">
			<div class="sub-wrapper">
				<div :class="`text-md-h4 text-h5 font-weight-medium text-center mt-md-0 title ${!success ? 'mt-7' : 'mt-2 mt-md-7'}`">{{ text.header }}</div>
				<v-form ref="form" @submit.prevent="submit" class="mt-8 mb-8 prevent-default-form">
				  <v-text-field
					  name="client-number"
					  :label="text.inputLabel"
					  id="client-number"
					  append-icon="mdi-key-variant"
					  class="rounded-lg mb-6"
					  outlined
					  color="accent"
					  v-model="phoneNumber"
					  :rules="requiredRule"
					  maxlength="12"
					  hide-details
					  required
					></v-text-field>
					<div class="text-center">
            <v-btn
            color="info"
            id="verify-btn"
            type="submit"
            min-width="125px"
            :disabled="success"
          >{{ text.button }}</v-btn>
          </div>
				</v-form>
				<div class="text-center">
					<v-progress-circular
						indeterminate
						color="primary"
						size="50"
						width="5"
						v-show="loading"
					></v-progress-circular>
				</div>
				<v-alert type="success" class="text-center rounded-lg" :icon="false" elevation="2" v-if="success">
					<span class="text-h6">{{ text.successAlert.header }}</span>
					<p class="text-body-1">{{ text.successAlert.part1 }} <b>{{ formName }}</b> {{ text.successAlert.part2 }} <b>{{ clientName }}</b>?</p>
          <p class="text-body-2 mt-n2">{{ text.successAlert.misentered }}.</p>
					<v-btn color="accent" rounded class="px-10" @click="confirm">{{ text.successAlert.button }}</v-btn>
				</v-alert>
				<v-alert type="error" class="text-center" dismissible :icon="false" elevation="2" v-if="error">
					{{ error }}
				</v-alert>
			</div>
		</v-card>
	</div>
</template>

<script>
import { title, verifyAccess } from './../js/text'
import { accessForm } from './../js/firebase-functions'

export default {
	name: 'VerifyAccess',
	data: function() {
		return {
			phoneNumber: null,
			requiredRule: [i => this.submitted ? (!!i || false) : true],
			submitted: false,
			loading: false,
			clientName: null,
			success: false,
			error: null,
		}
	},
	computed: {
		lang() {
			let lang = this.$router.history.current.query.lang
			if (lang != 'en' && lang != 'sp') lang = 'en'
			return lang
		},
		text() {
			return verifyAccess[this.lang]
		},
		formName() {
			return title[this.lang]
		}
	},
	methods: {
		submit() {
			this.submitted = true
			this.$refs.form.validate()
			if (this.phoneNumber) {
				this.loading = true
				this.error = false
				this.success = false
				if (!this.phoneNumber.includes('-')) {
					this.phoneNumber = this.phoneNumber.substring(0,3) + '-' + this.phoneNumber.substring(3,6) + '-' + this.phoneNumber.substring(6)
				}
				accessForm(this.phoneNumber).then(res => {
					const data = res.data()
					if (data === undefined) {
						this.loading = false
						this.error = this.text.errors.invalidNumber
					} else {
						this.clientName = res.data().client_name
						this.success = true
						this.loading = false
					}
				}).catch(err => {
					if (process.env.NODE_ENV == 'development') console.error(err)
					this.loading = false
					this.error = this.text.errors.unkown
				})
			}
		},
		confirm() {
			this.$router.push({ path: '/tax-credit-screening', query: {
				lang: this.lang,
				number: this.phoneNumber,
        extended: this.$router.history.current.query.extended
			}})
		}
	}
}
</script>