<template>
  <v-app id="hudson-forms">
    <navbar></navbar>
    <v-main class="app-main">
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer color="primary" class="app-footer" app>
      <span class="ml-5">&#169; 2020, Hudson Group. All rights reserved.</span>
      <span class="ml-5">Fax number: <b>603-869-7373</b></span>
    </v-footer>
  </v-app>
</template>

<script>
import navbar from './modules/core/components/Navbar'
import { auth } from './js/firebase/init'
import { onAuthStateChanged } from 'firebase/auth'

export default {
  name: 'App',
  components: {
    navbar
  },
  created: function() {
    onAuthStateChanged(auth, () => this.$store.dispatch('auth', {}))
  },
  mounted() {
    this.maxHeight()
  },
  watch: {
    $route() {
      this.maxHeight()
    }
  },
  methods: {
    maxHeight() {
      if (this.$route.name == 'Employee Info') {
        document.querySelector('.v-main.app-main').style['max-height'] = '100vh'
      } else {
        document.querySelector('.v-main.app-main').style['max-height'] = 'none'
      }
    }
  }
}
</script>