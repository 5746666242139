<template>
   <div class="text-center" :class="{ 'continue-down text-center': !toRight, 'd-inline-block icon-wrapper': toRight }" v-if="showScrollArrow">
      <v-icon color="error" large>mdi-arrow-down-bold-circle-outline</v-icon>
      <div class="text-caption mt-n1">(scroll down)</div>
    </div>
</template>

<script>
export default {
  name: 'ScrollDownArrow',
  props: {
    showScrollArrow: Boolean,
    toRight: Boolean
  }
}
</script>