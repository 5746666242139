import { user } from '@/router/guards'
const gaurd = (to, from, next) => user(to, next, ['admin', 'super-admin'])

export default [
  {
    path: '/admin/new-client-stores',
    name: 'Add Client Stores',
    component: () => import(/* webpackChunkName: "admin" */ './views/NewClientStores'),
    beforeEnter: gaurd,
    meta: { title: 'Add Stores' }
  },
  {
    path: '/admin/review-screening-forms',
    name: 'Review Forms',
    component: () => import(/* webpackChunkName: "admin" */ './views/ReviewScreeningForms'),
    beforeEnter: gaurd,
    meta: { title: 'Reveiw Forms' }
  }
]