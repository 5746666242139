import { firestore } from '@/js/firebase/init'
import { doc, getDoc, query, collection, getDocs, setDoc } from 'firebase/firestore'

const getStores = function(number) {
	const ref = doc(firestore, 'client_stores', number)
	return getDoc(ref).then(doc => {
		return doc.data().stores
	}).catch(err => {
		throw err
	})
}

function getClientName(number) {
	return new Promise((resolve, reject) => {
		const name = localStorage.getItem('client_name')
		if (name) {
			resolve(name)
		} else {
			const ref = doc(firestore, 'client_stores', number)
			getDoc(ref).then(doc => {
				const name = doc.data().client_name
				localStorage.setItem('client_name', name)
				resolve(name)
			}).catch(err => reject(err))
		}
	})
}

function getAllClients() {
	const q = query(collection(firestore, 'client_stores'))

	return getDocs(q)
}

function addStoreManager(client_number, store_number, name, email, uid) {
	const ref = doc(firestore, 'store_managers', uid)
	return setDoc(ref, { client_number, store_number, name, email })
}

export { getStores, getClientName, getAllClients, addStoreManager }