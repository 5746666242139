<template>
	<div class="px-3 home-wrapper logged-in">
		<div class="pl-6 header" v-if="!isAdmin">
			<span class="text-h3 primary--text text--darken-2">{{ clientName }}</span>
			<span
				class="text-h3 font-italic primary--text text--darken-2"
				v-if="role == 'store-manager' && !!clientName"
			>
				 - Store #{{ storeNumber }}
			</span>
		</div>
		<div v-if="isAdmin" class="pl-6 header text-h3 primary--text text--darken-2 text-capitalize">{{ roleLabel }}</div>
		<v-tooltip bottom nudge-top="7px">
      <template v-slot:activator="{ on, attrs }">
        <router-link :to="{ name: 'Account Settings' }" class="settings-link">
					<v-icon
						x-large
						class="primary--text text--darken-2"
						v-bind="attrs"
        		v-on="on"
					>
						mdi-cog
					</v-icon>
				</router-link>
      </template>
      <span class="tooltip">Settings</span>
    </v-tooltip>
		<div class="sub-wrapper">
			<div class="mt-8 d-flex flex-column">
				<div
					class="px-5 pb-5 d-flex flex-column flex-md-row justify-center justify-md-start card-row"
					v-for="i in rows.length"
				>
					<div class="z-index card-wrapper" v-for="card in rows[i - 1]">
						<card
							:title="card.title"
							:subtitle="card.subtitle"
							:text="card.text"
							:btn="{ path: card.path, text: card.btnText, color: card.btnColor }"
						></card>
					</div>
				</div>
			</div>
			<div class="home-info-div mx-n6 rounded-t-xl text-center"></div>
		</div>
	</div>
</template>

<script>
import card from '@/modules/core/components/HomeCard'
import { getClientName } from './../js/firebase-functions'
import views from './../js/role-views'
import { mapGetters } from 'vuex'

export default {
	name: 'AdminHome',
	components: {
		card
	},
	created: function() {
		this.storeNumber = this.user.storeNumber
		if (!this.isAdmin) this.setClientName()
		if (this.role) this.setCards()
	},
	data: function() {
 		return {
 			clientName: null,
 			storeNumber: null,
 			rows: []
 		}
	},
	computed: {
		...mapGetters(['user', 'role', 'roleLabel']),
		isAdmin() {
			return this.role == 'admin' || this.role == 'super-admin'
		}
	},
	watch: {
		role(val) {
			const unsubscribe1 = this.$store.subscribe(({ type }) => {
		    if (type == 'setClientNumber') {
		      this.setClientName()
		      unsubscribe1()
		    }
		  })
			const unsubscribe2 = this.$store.subscribe(data => {
		    if (data.type == 'setStoreNumber') {
		   		this.storeNumber = data.payload
		      unsubscribe2()
		    }
		  })
		  if (!this.rows[0]) this.setCards()
		}
	},
	methods: {
		setClientName() {
			getClientName(this.user.clientNumber).then(name => this.clientName = name)
		},
		setCards() {
			const cards = views[this.role]
			if (cards.length <= 3) return this.rows = [cards]
			this.rows = [cards.slice(0, 3), cards.slice(3)]
		}
	}
}
</script>