<template>
	<v-app-bar class="app-bar" color="primary" app>
		<router-link to="/" class="d-md-none d-flex justify-center mt-3 mobile">
			<img class="logo" :src="logo"/>
		</router-link>
    <router-link to="/" class="mt-4 ml-5 d-none d-md-block">
			<img class="logo" :src="logo"/>
		</router-link>
		<div class="flex-grow-1 text-right" v-if="role">
			<v-menu
				offset-y
				open-on-hover
				nudge-bottom="3"
				close-delay="100"
				:min-width="minWidth"
				transition="scroll-y"
				content-class="client-menu"
				v-if="verified"
			>
	      <template v-slot:activator="{ on, attrs }">
	        <v-btn
	        	color="yellow accent-2"
	          class="text-capitalize mt-3 client-menu-btn"
	          plain
	          ref="clientMenuBtn"
	          :to="{ path: `/${role}/views` }"
	          v-bind="attrs"
	          v-on="on"
	        >
	          {{ roleLabel }} menu
	        </v-btn>
	      </template>
	      <div class="px-4 mt-2">
	      	<span class="caption grey--text text--darken-2">Views</span>
	      	<v-divider class="mt-1 z-index"></v-divider>
	      </div>
	      <v-list class="text-capitalize">
	      	<v-list-item class="item">
	          <router-link :to="{ path: `/${role}/views` }" class="link">Home</router-link>
	        </v-list-item>
	      	<v-list-item v-for="item in userMenu" :key="item.title" class="item">
	          <router-link :to="item.path" class="link">{{ item.title }}</router-link>
	        </v-list-item>
	        <div class="px-4 mb-4">
	        	<v-divider class="z-index"></v-divider>
	        </div>
	        <v-list-item class="item">
	          <router-link :to="{ name: 'Account Settings' }" class="link">Account Settings</router-link>
	        </v-list-item>
	      </v-list>
	    </v-menu>
		  <sign-out-modal></sign-out-modal>
		</div>
  </v-app-bar>
</template>

<script>
import signOutModal from '@/modules/auth/components/SignOutModal'
import views from '@/modules/shared/js/role-views'
import { mapGetters } from 'vuex'

export default {
	name: 'Navbar',
	components: {
		signOutModal
	},
	data() {
		return {
			logo: require('@/assets/Hudson_Group_Logo.png'),
			btnSetupDone: false,
			userMenu: []
		}
	},
	computed: {
		...mapGetters(['verified', 'role', 'roleLabel']),
		minWidth() {
			if (this.role == 'super-admin' || this.role == 'store-manager') return '250px'
			return 0
		}
	},
	watch: {
		verified(val) {
			if (val && !this.btnSetupDone && this._isMounted) this.$nextTick(() => this.setUpClientMenuBtn())
		},
		role(val) {
			this.userMenu = views[val]
		}
	},
	methods: {
		setUpClientMenuBtn() {
			const $el = this.$refs.clientMenuBtn.$el
			$el.addEventListener('mouseenter', () => triggerRipple($el))
			this.btnSetupDone = true
		}
	}
}

function triggerRipple($el) {
	const ev = new Event('mousedown')
  const offset = $el.getBoundingClientRect()
  ev.clientX = offset.left + offset.width/2
  ev.clientY = offset.top + offset.height/2
  $el.dispatchEvent(ev)
  $el.addEventListener('mouseleave', () => $el.childNodes.forEach(child => {
  	if (child.className == 'v-ripple__container') child.style.display = 'none'
  }))
}
</script>

<style scoped lang="scss">
.client-menu-btn {
	font-size: 16px;
	margin-right: 75px;
}
.client-menu {
	background-color: white;
	.item {
		margin-top: -7px;
		padding: 0 10px;
		display: flex;
		
		&:not(:last-child) {
			color: var(--v-secondary-lighten1) !important;
		}
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			color: var(--v-info-darken2) !important;
		}
	}
	.link {
		padding: 5px 10px;
		border-radius: $border-radius-root;
		font-weight: 500;
		font-size: 14.5px;
		flex-grow: 1;
		text-decoration: none;
		color: inherit !important;

		a, a:hover {
			text-decoration: none;
			color: inherit !important;
		}
		&:hover {
			background-color: $background-hover-grey;
		}
		&:first-child {
			margin-top: -10px;
		}
	}
}
.z-index {
	z-index: 100;
}
</style>