import { login, resetPassword, actionCode, user } from '@/router/guards'

export default [
	{
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "auth" */ './views/Login'),
    beforeEnter: login,
    meta: { title: 'Login' }
  },
  {
    path: '/auth-action',
    name: 'Auth Loading',
    component: () => import(/* webpackChunkName: "auth" */ './views/AuthLoading'),
    beforeEnter: actionCode,
    meta: { title: 'Auth Action' }
  },
  {
    path: '/verify-email',
    name: 'Verify Email',
    component: () => import(/* webpackChunkName: "auth" */ './views/VerifyEmail'),
    beforeEnter: actionCode,
    meta: { title: 'Verify Email' }
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: () => import(/* webpackChunkName: "auth" */ './views/ForgotPassword'),
    meta: { title: 'Forgot Password' }
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: () => import(/* webpackChunkName: "auth" */ './views/ResetPassword'),
    beforeEnter: resetPassword,
    meta: { title: 'Reset Password' }
  },
  {
    path: '/reset-email',
    name: 'Reset Email',
    component: () => import(/* webpackChunkName: "auth" */ './views/ResetEmail'),
    beforeEnter: actionCode,
    meta: { title: 'Reset Password' }
  },
  {
    path: '/account-settings',
    name: 'Account Settings',
    component: () => import(/* webpackChunkName: "auth" */ './views/AccountSettings'),
    beforeEnter: (to, from, next) => user(to, next, ['client', 'store-manager', 'admin', 'super-admin']),
    meta: { title: 'Account Settings' }
  }
]