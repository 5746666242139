<template>
	<div class="not-found-wrapper d-flex align-center justify-center">
		<div class="text-center">
			<h1>Sorry. It appears this page doesn't exist</h1>
			<v-icon color="error">mdi-alert-octagon</v-icon>
			<br>
			<v-btn class="mb-15" color="success" width="50%"  large to="/">Home</v-btn>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NotFound'
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');

.not-found-wrapper {
	height: 100%;
	font-family: 'Fredoka One', cursive;

	h1 {
		font-size: 50px;
		font-weight: 100;
	}
	.v-icon {
		font-size: 400px;
	}
}
</style>