import { user } from '@/router/guards'
import PortalHome from './views/PortalHome'
import AddUsers from './views/NewUser'

export default [
	{
		path: '/:role/views',
		name: 'Portal Home',
		component: PortalHome,
		beforeEnter: (to, from, next) => user(to, next, ['store-manager', 'client', 'admin', 'super-admin']),
		title: { title: 'Portal Home' }
	},
	{
    path: '/:role/add-users/:canAdd',
    name: 'Add Users',
    component: AddUsers,
    beforeEnter: (to, from, next) => user(to, next, ['client', 'admin', 'super-admin']),
    meta: { title: 'Add Users' }
  }
]