export default {
	setNeedsEmailVerification(state, val) {
		state.needsEmailVerification = val
	},
	setVerified(state, val) {
		state.verified = val
	},
	setUser(state, user) {
		state.user = user
	},
	setClientNumber(state, number) {
		state.user.clientNumber = number
	},
	setStoreNumber(state, number) {
		state.user.storeNumber = number
	},
	setRole(state, role) {
		state.role = role
	}
}