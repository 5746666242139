<template>
	<div class="height-100 d-flex justify-center align-center">
		<div id="info-page-card" class="text-center rounded-lg">
			<slot name="title"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'InfoPage'
}
</script>

<style scoped lang="scss">
#info-page-card {
	width: 70%;
	padding: 100px;
	background-color: var(--v-info-base);
	color: var(--v-accent-base);

	.page-info-text {
		font-size: 20px;
	}
}
</style>