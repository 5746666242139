<template>
	<div>
		<div class="text-h3 d-inline-block accent--text text--lighten-1">{{ title }}</div>
		<v-card dark class="rounded-lg elevation-3 d-inline-block float-right" v-if="!noRoleLabel && roleLabel">
			<div class="pa-3 d-flex">
	     	<v-icon color="info" class="mr-3">mdi-information</v-icon>
	     	<div class="text-center text-capitalize flex-grow-1 font-italic mr-1">{{ roleLabel }} only page</div>
	    </div>
		</v-card>
		<hr class="divider mt-3 mb-7">
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'PageTitle',
	props: {
		title: {
			type: String,
			required: true
		},
		noRoleLabel: Boolean
	},
	computed: {
		...mapGetters(['roleLabel'])
	}
}
</script>

<style scoped lang="scss">
.divider {
	border: 1px solid var(--v-accent-lighten1);
}
</style>