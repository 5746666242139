import { functions } from '@/js/firebase/init'
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions'

if (process.env.NODE_ENV == 'development') connectFunctionsEmulator(functions, 'localhost', 5001)

function sendFormReminder(data) {
	const fn = httpsCallable(functions, 'formReminder')
	fn(data)
}

export { sendFormReminder }