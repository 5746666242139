import VerifyAccess from './views/VerifyAccess'
import SuccessMessage from './views/SuccessMessage'
import Form from './views/Form'

export default [
	{
    path: '/access',
    name: 'Access Form',
    component: VerifyAccess,
    meta: { title: 'Access Form' }
  },
	{
		path: '/tax-credit-screening',
		name: 'Tax Credit Screening',
		component: Form,
		meta: { title: 'Tax Credit Screening' }
	},
  {
    path: '/success',
    name: 'Success Message',
    component: SuccessMessage,
    meta: { title: 'SUCCESS' }
  }
]