<template>
	<div class="mb-5 d-flex justify-space-between mobile-btn-div mt-3">
		<v-btn
			depressed
			color="accent"
			class="mobile-action-btn"
			@click="previous"
			:disabled="stepper == 1"
		>
			{{ text.previous }}
		</v-btn>
		<v-btn
			depressed
			:color="!submit ? 'info' : 'success'"
			:class="submit ? 'elevation-5 mobile-action-btn' : 'mobile-action-btn'"
			@click="next"
			:disabled="disabled"
			:loading="loading"
		>
			{{ !submit ? text.next : text.buttonShort }}
		</v-btn>
	</div>
</template>

<script>
export default {
	name: 'MobileProgressButtons',
	props: {
		stepper: Number,
		disabled: Boolean,
		submit: Boolean,
		loading: Boolean,
		text: Object
	},
	methods: {
		previous() {
			this.$emit('previous')
		},
		next() {
			this.$emit('next')
		}
	}
}
</script>