export default {
	needsEmailVerification: state => {
		return state.needsEmailVerification
	},
	verified: state => {
		return state.verified
	},
	user: state => {
		return state.user
	},
	role: state => {
		return state.role
	},
	roleLabel: state => {
		if (!state.role) return null
		return state.role.split('-').join(' ')
	}
}