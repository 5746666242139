import states from '@/assets/states'

const title = {
	en: 'Tax Credit Screening',
	sp: 'Evaluación de Crédito Fiscal'
}

const verifyAccess = {
	en: {
		header: 'Verify Your Access',
		inputLabel: 'Phone Number/ Key',
		button: 'verify',
		errors: {
			invalidNumber: 'Number is invalid',
			unkown: 'Unknown error. Please try again'
		},
		successAlert: {
			header: 'VERIFIED',
			part1:  'Fill out a',
			part2: 'form for',
      misentered: 'If you misentered the number, please reload',
			button: 'yes'
		}
	},
	sp: {
		header: 'Verificar su Acceso',
		inputLabel: 'Número de Teléfono / Clave',
		button: 'verificar',
		errors: {
			invalidNumber: 'El número de teléfono no es válido',
			unkown: 'Error desconocido. Por favor, inténtelo de nuevo'
		},
		successAlert: {
			header: 'VERIFICADO',
			part1:  'Complete un',
			part2: 'formulario para',
      misentered: 'Si ingresó mal el número, vuelva a cargar',
			button: 'sí'
		}
	}
}

const form = {
	en: {
		loc: 'Location',
		locHint: 'If your location is not present, type it in.',
		select: 'Select',
		wc: 'Wrong Client? Re-Enter Phone Number',
		ln: 'Last Name',
		fn: 'First Name',
		mi: 'Middle Initial',
		phone: 'Phone Number',
		email: 'Email',
		address: 'Address',
		s1: 'Street 1',
		s2: 'Street 2 (optional)',
		city: 'City',
		state: 'State/ Region',
		zip: 'Postal code',
		ssn: 'Social Security Number',
		ssnHint: 'Do not copy and paste any part of your ssn',
		under40: 'Are you under the age of 40?',
		dob: 'Date of Birth',
		hireDate: 'Hire Date',
		sqTitle: 'Survey Questions',
		surveyQuestions:  [
      'Have you worked for this employer before?',
      'Are you a veteran of the U.S. Armed Forces?',
      'Have you or a family member received food stamps in the last year?',
      'Have you or a family member received cash assistance payments such as TANF (Temporary Assistance for Needy Families) in the last year?',
      'During the last year have you ever been convicted of a felony, released from prison, or are you on work release?',
      'Before today, have you been unemployed for the last six months?',
      'Were you receiving Non-Retirement Based Supplemental Security Income (SSI) from the SSA prior to accepting this job?',
      'Have you applied to Veterans Affairs, Vocational Rehabilitation Agency, or an Employment Network under the Ticket to Work Program to help find a job?',
      'Are you or your spouse a member of a Native American Tribe?'
    ],
    surveyQuestionsExtended: [
      {
        question: 'Have you or a family member received food stamps within the last year?',
        marginBottom: -30,
        followups: [
          {
            text: 'Please provide the name of the primary recipient:',
            type: 'text',
            // ref: 'primaryRecipient'
          }
        ]
      },
      {
        question: 'Are you a veteran of the U.S. Armed Forces?',
        marginBottom: -70,
        followupsHeader: 'Please fill out the information below:',
        followups: [
          {
            text: 'Have you been unemployed for at least 4 weeks but less than 6 months in last year?',
            type: 'binary',
            // ref: 'unemployed1'
          },
          {
            text: 'Have you been unemployed for periods of time totaling at least 6 months last year?',
            type: 'binary',
            // ref: 'unemployed2'
          },
          {
            text: 'Are you entitled to compensation for a service-connected disability and released from active duty within the last year?',
            type: 'binary',
            // ref: 'disabilityCompensation'
          }
        ]
      },
      {
        question: 'Have you or a family member received cash assistance payments such as TANF(Temporary Assistance for Needy Families) within the last year?',
        marginBottom: -20,
        followups: [
          {
            text: 'Please provide the name of the primary recipient:',
            type: 'text',
            // ref: 'primaryRecipient'
          }
        ]
      },
      {
        question: 'Are you an ex-felon?',
        marginBottom: -70,
        followupsHeader: 'Please fill out the information below:',
        followups: [
          {
            text: 'Conviction Type',
            options: ['Federal', 'State'],
            type: 'select',
            // ref: 'convictionType'
          },
          {
            text: 'State Convicted in:',
            type: 'select',
            options: states,
            showIfOption: {
              questionIndex: 0,
              option: 'State'
            },
            // ref: 'stateConvictedIn'
          },
          {
            text: 'Conviction Date:',
            type: 'text',
            date: true,
            // ref: 'convictionDate'
          },
          {
            text: 'Release Date:',
            type: 'text',
            date: true,
            // ref: 'releaseDate'
          }
        ]
      },
      {
        question: 'Were, or are you receiving Non-Retirement Based Supplemental Security Income (SSI)?'
      },
      {
        question: 'Before today, have you been unemployed for the last six months, and received ANY unemployment benefits?'
      },
      {
        question: 'Are you a vocational rehab referral?',
        marginBottom: -70,
        followupsHeader: 'Please indicate which of the following referred you:',
        followups: [
          {
            text: 'Rehabilitation agency approved by the state:',
            type: 'binary',
            // ref: 'refferedByRehibilationAgency'
          },
          {
            text: 'Employment Network under the ticket to work program:',
            type: 'binary',
            // ref: 'refferedByEmploymentNetwork'
          },
          {
            text: 'Department of Veteran Affairs:',
            type: 'binary',
            // ref: 'refferedByDepartmentofVeterans'
          }
        ]
      }
    ],
    y: 'Yes',
		n: 'No',
		next: 'next',
		previous: 'previous',
		errors: {
			r: 'required',
			email: 'invalid email',
			zip: 'invalid postal code',
			wrongFormat: 'invavlid format',
			finalSSN: "ssn digits don't match",
			fix: 'Fix the errors',
			unknown: 'An unknown error occurred. Please try again later'
		},
		closing:  {
			title: 'Closing Disclosure',
			main: 'By providing the last 4 of your SSN you are authorizing the electronic signature of a voluntary form, which allows the Hudson Group or its agents access to information held by any parties needed to determine m y eligibility for federal and/or state tax credit programs. This includes but is not limited to any information outlined in this questionnaire. I further authorize the Hudson Group or its agents to complete on my behalf any forms required to obtain this information.',
			inputHeader: 'Provide the last 4 of your SSN to accept the above statement',
			ssn: 'Last 4 SSN',
			date: 'Date'
		},
		button: 'submit and record',
		buttonShort: 'submit',
		record: 'Please record you confirmation number on successful submission',
		infoText: 'Submitting form to',
    optional: 'optional',
    date: {
      year: 'Year',
      month: 'Month',
      day: 'Day'
    },
    fixError: 'Fix Errors'
	},
	sp: {
		loc: 'Ubicación',
		locHint: 'Si su ubicación no está presente, escríbala.',
		select: 'Seleccione',
		wc: '¿Cliente incorrecto? Vuelva a ingresar el número de teléfono',
		ln: 'Apellido',
		fn: 'Nombre de pila',
		mi: 'Inicial del segundo nombre',
		phone: 'Número de teléfono',
		email: 'Dirección de correo electrónico',
		address: 'Dirección',
		s1: 'Calle 1',
		s2: 'Calle 2 (opcional)',
		city: 'Ciudad',
		state: 'Estado / Región',
		zip: 'Código postal',
		ssn: 'Número de seguridad social',
		ssnHint: 'No copie ni pegue ninguna parte de su ssn',
		under40: '¿Tienes menos de 40 años?',
		dob: 'Fecha de nacimiento',
		hireDate: 'Fecha de contratación',
		sqTitle: 'Preguntas de la encuesta',
		surveyQuestions:  [
      '¿Ha trabajado antes para este empleador?',
      '¿Es veterano de las Fuerzas Armadas de EE.UU.?',
      '¿Ha recibido usted o un miembro de su familia cupones de alimentos en el último año?',
      '¿Ha recibido usted o un miembro de su familia pagos de asistencia en efectivo como TANF (Asistencia Temporal para Familias Necesitadas) en el último año?',
      'Antes de hoy, ¿ha estado desempleado durante los últimos seis meses?',
      'Antes de hoy, ¿ha estado desempleado durante los últimos seis meses?',
      '¿Recibía Ingresos suplementarios de seguridad (SSI) no basados en la jubilación de la SSA antes de aceptar este trabajo?',
      '¿Ha presentado una solicitud a Asuntos de Veteranos, Agencia de Rehabilitación Vocacional o una Red de Empleo bajo el Programa Boleto para Trabajar para ayudar a encontrar un trabajo?',
      '¿Es usted o su cónyuge miembro de una tribu nativa americana?'
    ],
    surveyQuestionsExtended: [
      {
        question: '¿Usted o un miembro de su familia ha recibido cupones de alimentos durante el último año?',
        marginBottom: -30,
        followups: [
          {
            text: 'Proporcione el nombre del destinatario principal:',
            type: 'text',
            // ref: 'primaryRecipient'
          }
        ]
      },
      {
        question: '¿Es usted un veterano de las Fuerzas Armadas de Estados Unidos?',
        marginBottom: -70,
        followupsHeader: 'Por favor llene la información de abajo:',
        followups: [
          {
            text: '¿Estuvo usted desempleado durante al menos 4 semanas pero menos de 6 meses durante el año pasado?',
            type: 'binary',
            // ref: 'unemployed1'
          },
          {
            text: '¿Estuvo usted desempleado durante períodos de tiempo que totalizaron al menos 6 meses el año pasado?',
            type: 'binary',
            // ref: 'unemployed2'
          },
          {
            text: '¿Tiene usted derecho a una compensación por una discapacidad relacionada con el servicio y fue dado de baja del servicio activo durante el último año?',
            type: 'binary',
            // ref: 'disabilityCompensation'
          }
        ]
      },
      {
        question: '¿Usted o un miembro de su familia ha recibido pagos de asistencia en efectivo como TANF (Asistencia Temporal para Familias Necesitadas) durante el último año?',
        marginBottom: -20,
        followups: [
          {
            text: 'Proporcione el nombre del destinatario principal:',
            type: 'text',
            // ref: 'primaryRecipient'
          }
        ]
      },
      {
        question: '¿Eres un ex delincuente?',
        marginBottom: -70,
        followupsHeader: 'Por favor llene la información de abajo:',
        followups: [
          {
            text: 'Tipo de condena',
            options: ['Federal', 'Estado'],
            type: 'select',
            // ref: 'convictionType'
          },
          {
            text: 'Estado condenado en:',
            type: 'select',
            options: states,
            showIfOption: {
              questionIndex: 0,
              option: 'Estado'
            },
            // ref: 'stateConvictedIn'
          },
          {
            text: 'Fecha de la condena:',
            type: 'text',
            date: true,
            // ref: 'convictionDate'
          },
          {
            text: 'Fecha de lanzamiento:',
            type: 'text',
            date: true,
            // ref: 'releaseDate'
          }
        ]
      },
      {
        question: '¿Recibió o está recibiendo Ingreso de Seguridad Suplementario (SSI) no basado en la jubilación?'
      },
      {
        question: 'Antes de hoy, ¿ha estado desempleado durante los últimos seis meses y ha recibido ALGÚN beneficio de desempleo?'
      },
      {
        question: '¿Es usted una referencia de rehabilitación vocacional?',
        marginBottom: -70,
        followupsHeader: 'Indique cuál de los siguientes le hizo referencia:',
        followups: [
          {
            text: 'Agencia de rehabilitación aprobada por el estado:',
            type: 'binary',
            // ref: 'refferedByRehibilationAgency'
          },
          {
            text: 'Red de Empleo en el marco del programa Boleto al Trabajo:',
            type: 'binary',
            // ref: 'refferedByEmploymentNetwork'
          },
          {
            text: 'Departamento de Asuntos de Veteranos:',
            type: 'binary',
            // ref: 'refferedByDepartmentofVeterans'
          }
        ]
      }
    ],
		y: 'Sí',
		n: 'No',
		next: 'próximo',
		previous: 'anterior',
		errors: {
			r: 'necesario',
			email: 'email inválido',
			zip: 'código postal no válido',
			wrongFormat: 'formato inválido',
			finalSSN: 'los dígitos del ssn no coinciden',
			fix: 'Corregir los errores',
			unknown: 'Un error desconocido ocurrió. Por favor, inténtelo de nuevo más tarde'
		},
		closing:  {
			title: 'Divulgación de cierre',
			main: 'Al proporcionar los últimos 4 de su SSN, usted autoriza la firma electrónica de un formulario voluntario, que permite al Hudson Group o sus agentes acceder a la información en poder de las partes necesarias para determinar mi elegibilidad para los programas de crédito fiscal federal y / o estatal. Esto incluye, pero no se limita a, cualquier información descrita en este cuestionario. Además, autorizo a Hudson Group o sus agentes a completar en mi nombre los formularios necesarios para obtener esta información.',
			inputHeader: 'Proporcione los últimos 4 de su SSN para aceptar la declaración anterior.',
			ssn: 'Últimos 4 SSN',
			date: 'Fecha'
		},
		button: 'enviar y grabar',
		buttonShort: 'enviar',
		record: 'Por favor, registre su número de confirmación en el envío exitoso',
		infoText: 'Enviando formulario a',
    optional: 'opcional',
    date: {
      year: 'Año',
      month: 'Mes',
      day: 'Día'
    }
	}
}

const youthQuestionnaire = {
	en: {
		title: 'NY Youth Questionnaire',
		main: 'Please read through the following list of statements and if any apply to you check the yes box below. If you think one applies to you but are not positive, please still check the box. Answering yes to any of these questions in no way harms your employment opportunity.',
		bullets: [ 'I am over 18 and do not have a high school diploma or a GED/TASC/HSE diploma.', 'I am a member of a family that is receiving assistance from TANF.', 'I am a member of a family that is receiving SNAP benefits (food stamps).', 'I am a member of a family that is receiving SSI benefits.', 'I am receiving a free or reduced-cost school lunch.', 'I was referred here by a rehabilitation agency approved by the state, or an employment network under the Ticket to Work Program.', 'I have served in jail or prison - or am on probation or parole.', 'I am pregnant or a parent.', 'I am homeless.', 'I am currently or was in foster care or the custody of the Office of Children and Family Services.', 'I am a veteran.', 'I am the daughter or son of a parent who is currently in jail or prison or has been within in the past two years.', 'I am the daughter or son of a parent who is collecting unemployment insurance.', 'I live in public housing or receive housing assistance such as a Section 8 voucher.', 'Another risk factor not identified above.' ],
		no: 'No',
		yes: 'Yes'
	},
	sp: {
		title: 'Cuestionario para jóvenes de NY',
		main: 'Lea la siguiente lista de declaraciones y, si alguna le corresponde, marque la casilla de sí a continuación. Si cree que uno se aplica a usted pero no es positivo, marque la casilla. Responder afirmativamente a cualquiera de estas preguntas no perjudica en modo alguno su oportunidad de empleo.',
		bullets: [ 'Soy mayor de 18 años y no tengo un diploma de escuela secundaria ni un diploma de GED/TASC/HSE.', 'Soy miembro de una familia que recibe asistencia de TANF.', 'Soy miembro de una familia que recibe beneficios de SNAP (cupones de alimentos).', 'Soy miembro de una familia que recibe beneficios de SSI.', 'Estoy recibiendo un almuerzo escolar gratuito o de precio reducido.', 'Fui referido aquí por una agencia de rehabilitación aprobada por el estado, o una red de empleo bajo el Programa Boleto para Trabajar.', 'He servido en la cárcel o en prisión, o estoy en libertad condicional o en libertad condicional.', 'Estoy embarazada o soy padre.', 'Soy un vagabundo.', 'Actualmente estoy o estuve en cuidado de crianza o bajo la custodia de la Oficina de Servicios para Niños y Familias.', 'Soy un veterano', 'Soy hija o hijo de un padre que se encuentra actualmente en la cárcel o prisión o que ha estado en los últimos dos años.', 'Soy hija o hijo de un padre que cobra el seguro de desempleo.', 'Vivo en una vivienda pública o recibo asistencia para la vivienda, como un vale de la Sección 8.', 'Otro factor de riesgo no identificado anteriormente.' ],
		no: 'No',
		yes: 'Sí'
	}
}

const successMessage = {
	en: {
		title: 'Success',
		p1: 'Thank you',
		p2: 'for submitting your',
		p3: 'form. Your confirmation code is',
		p4: 'We recommend you save it somewhere you can remember.'
	},
	sp: {
		title: 'Éxito',
		p1: 'Gracias',
		p2: 'por enviar su',
		p3: 'formar. Tu código de confirmación es',
		p4: 'Le recomendamos que lo guarde en algún lugar que pueda recordar.'
	}
}

export { title, verifyAccess, form, youthQuestionnaire, successMessage }