import axios from 'axios'
import { auth } from './firebase/init'
import { getIdToken } from 'firebase/auth'

const instance = axios.create({
  baseURL: `${process.env.VUE_APP_FUNCTIONS_URL}/api`,
  timeout: 10000
})

function reqWithAuth(route, reqType, options) {
	return getIdToken(auth.currentUser).then(idToken  => {
		options.headers = { auth: idToken }
		return req(route, reqType, options)
	}).catch(err => {
		if (process.env.NODE_ENV == 'development') console.error(err)
		throw err
	})
}

function reqNoAuth(route, reqType, options) {
	return req(route, reqType, options).catch(err => {
		if (process.env.NODE_ENV == 'development') console.error(err)
		throw err
	})
}

function req(route, reqType, options, headers) {
	const req = {
		url: route,
		method: reqType,
	}
	if (options.data) req.data = options.data
	if (options.params) req.params = options.params
	if (options.headers) req.headers = options.headers
	return instance.request(req)
}

export { reqWithAuth, reqNoAuth }