import { firestore } from '@/js/firebase/init'
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'

const formName = 'tax_credit_screening'

const accessForm = function(number) {
	const ref = doc(firestore, 'client_stores', number)
	return getDoc(ref)
}

const submitForm = function(docId, form, extended) {
	const ref = doc(firestore, getCollectionName(extended), docId)
	return setDoc(ref, form)
}

const formId = function(extended) {
  const ref = doc(firestore, getCollectionName(extended), 'metadata')
	return getDoc(ref).then(doc => {
		updateDoc(ref, { currentSubmissionId: doc.data().currentSubmissionId + 1 }).catch(err => {
			if (process.env.NODE_ENV == 'development') console.error(err)
		})
		return doc.data().currentSubmissionId
	}).catch(err => {
		return 'err'
	})
}

function getCollectionName(extended) {
  let collection = formName
  if (extended) collection += '_extended'
  return collection;
}

export { accessForm, submitForm, formId }