<template>
	<div :id="id" :class="{ 'flex-grow-1': flexGrow }">
		<v-select
			:items="items"
			:placeholder="placeholder"
			solo
			:flat="flat"
			:color="color"
			:loading="loading ? 'grey' : false"
			:disabled="disabled"
			validate-on-blur
			:rules="rules"
			append-icon=""
			:menu-props="{
				'offset-y': true,
				'content-class': 'text-capitalize ' + backgroundColor + (lowercase ? ' text-lowercase' : '')
			}"
			:background-color="backgroundColor"
			:dense="dense"
			class="rounded text-capitalize select-directive"
			:class="{ 'text-lowercase': lowercase }"
			v-solo-select-error:[directiveArg]="error"
			v-model="value"
			@change="update"
		>
			<template v-slot:append>
				<v-icon class="dropdown-icon" v-show="down">mdi-chevron-down</v-icon>
				<v-icon class="dropdown-icon" v-show="!down">mdi-chevron-up</v-icon>
			</template>
		</v-select>
	</div>
</template>

<script>
import soloSelectError from './../js/solo-select-error'

export default {
	name: 'CustomSelect',
	props: {
		id: {
			type: String,
			required: true
		},
		data: {
			type: Array,
			required: true
		},
		valueKey: String,
		initialVal: [String, Object],
		placeholder: {
			type: String,
			default: 'Select'
		},
		firstSubmit: Boolean,
		flat: Boolean,
		dense: Boolean,
		error: Boolean,
		loading: Boolean,
		flexGrow: Boolean,
		lowercase: Boolean,
		color: {
			type: String,
			default: 'white'
		},
		backgroundColor: {
			type: String,
			required: true
		}
	},
	directives: {
		soloSelectError
	},
	created: function() {
		this.itemsChange()
	},
	data: function() {
		return {
			value: null,
			down: true,
			arrowTimeout: 100
		}
	},
	computed: {
		items() {
			if (!this.valueKey) return this.data

			const arr = []
			for (const x in this.data) {
				arr.push({
					text: this.data[x][this.valueKey],
					value: this.data[x]
				})
			}
			return arr
		},
		directiveArg() {
			return {
				customSelect: true,
				firstSubmit: this.firstSubmit,
				id: this.id
			}
		},
		rules() {
			return [() => !this.error || 'required']
		},
		disabled() {
			return this.loading || this.items.length == 1 || this.items.length == 0
		}
	},
	watch: {
		items() {
			this.itemsChange()
		}
	},
	methods: {
		update(val) {
			this.$emit('update', val)
		},
		itemsChange() {
			if (this.items.length == 1) this.value = this.items[0]
			if (this.initialVal) this.value = this.initialVal 
		}
	}
}
</script>