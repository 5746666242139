import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions';
import fireConfig from './config'

const app = initializeApp(fireConfig)

const firestore = getFirestore(app)
const auth = getAuth(app)
const storage = getStorage(app)
const functions = getFunctions(app)

export { firestore, auth, storage, functions }