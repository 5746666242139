function clickOnDetails(el) {
	return el.classList[0] == 'v-messages' || el.classList[0] == 'v-text-field__details'
}
let errController = {}
let routeChange = {}

export default {
	// binding.arg is an object ({ customSelect: Boolean, firstSubmit: Boolean, id: String }) and binding.value is error
	inserted(el, binding) {
		main(el, binding)
	},
	update(el, binding) {
    if (binding.arg.firstSubmit || !binding.value(binding.arg.formBindings)) errController[binding.arg.id].abort()

    if (binding.arg.firstSubmit && binding.value(binding.arg.formBindings)) {
			el.parentElement.classList.add('err')
			if (binding.arg.customSelect) document.querySelectorAll('.dropdown-icon').forEach(el => el.classList.add('err'))
		} else {
			el.parentElement.classList.remove('err')
			if (binding.arg.customSelect) document.querySelectorAll('.dropdown-icon').forEach(el => el.classList.remove('err'))
		}
	}
}

function main(el, binding) {
  let prevClickOnBtn = false
  errController[binding.arg.id] = new AbortController()
  routeChange[binding.arg.id] = new AbortController()
  el.addEventListener('click', ev => {
    if (!binding.arg.firstSubmit && !clickOnDetails(ev.path ? ev.path[0] : ev.target)) {
      el.parentElement.classList.remove('err')
      if (binding.arg.customSelect) document.querySelectorAll('.dropdown-icon').forEach(el => el.classList.remove('err'))
    }
  }, { signal: errController[binding.arg.id].signal })
  window.addEventListener('click', ev => {
    let target = ev.path ? ev.path[0] : ev.target
    let clickOnBtn = target == el
    for (let x = 0; x < 5; x++) {
      if (clickOnBtn || !target) break
      target = target.parentElement
      clickOnBtn = target == el
    }
    if (prevClickOnBtn && !clickOnBtn && binding.value(binding.arg.formBindings)) {
      el.parentElement.classList.add('err')
      if (binding.arg.customSelect) document.querySelectorAll('.dropdown-icon').forEach(el => el.classList.add('err'))
    }
    prevClickOnBtn = clickOnBtn
  }, { signal: errController[binding.arg.id].signal })
  if (binding.arg.customSelect) el.addEventListener('click', ev => {
    if (!clickOnDetails(ev.path ? ev.path[0] : ev.target)) {
      const classList = document.querySelector('.dropdown-icon').classList
      classList.remove('mdi-chevron-down')
      classList.add('mdi-chevron-up')
    }
  }, { signal: routeChange[binding.arg.id].signal })
  if (binding.arg.customSelect) window.addEventListener('click', ev => {
    let target = ev.path ? ev.path[0] : ev.target
    for (let x = 0; x < 5; x++) {
      if (!target) continue
      target = target.parentElement
    }
    if (target != el) {
      try {
        const classList = document.querySelector('.dropdown-icon').classList
        classList.remove('mdi-chevron-up')
        classList.add('mdi-chevron-down')
      } catch (err) { }
    }
  }, { signal: routeChange[binding.arg.id].signal })
  // use this event listener to listen for route change and then abort all other event listeners
  window.addEventListener('click', () => {
    if (!document.getElementById(binding.arg.id)) {
      errController[binding.arg.id].abort()
      routeChange[binding.arg.id].abort()
    }
  })
  if (binding.arg.mobileVersionPromise) binding.arg.mobileVersionPromise.then(newPromise => {
    const newBinding = binding
    newBinding.arg.mobileVersionPromise = newPromise
    errController[binding.arg.id].abort()
    main(el, newBinding)
  })
}