<template>
  <div>
    <v-card class="pa-10 rounded-lg d-none d-lg-block">
      <v-btn
        color="yellow darken-1"
        elevation="2"
        rounded
        class="px-7 py-5 mt-n3"
        absolute
        :ripple="false"
        @click="changeLang"
      >
        {{ lang == 'en' ? 'Español' : 'English' }}
      </v-btn>
      <v-form ref="form" @submit.prevent="submit" autocomplete="off" class="mt-10">
        <v-row v-for="(row, i) in inputs.fields" :class="row.rowClass" :key="`row-${i}`">
          <v-col v-for="(col, i) in row.cols" :cols="calcCols(col.cols)" :offset="calcCols(col.offset)" :class="getColClass(col)" :key="`col-${i}`">
            <v-btn v-if="col.wrongNumberBtn" color="accent text-capitalize" type="button" rounded @click="wrongNumber" class="mt-3">{{ text.wc }}</v-btn>
            <label
              v-if="col.type == 'label'"
              :class="getLabelClass(col) + ' btm text-no-wrap'"
              :style="getLabelStyle(col.labelStyle)"
            >{{ col.text }}</label>
            <div :id="col.ref" v-if="col.type == 'combo'" style="width: 100%">
              <v-combobox
                color="white"
                background-color="info"
                class="select-directive"
                v-solo-select-error:[directiveArg(col.ref)]="testRules(col.rules, col.ref)"
                dense
                solo
                persistent-hint
                :label="col.label"
                :items="col.items"
                :hint="col.hint"
                v-model="formBindings[col.ref]"
                :rules="col.rules"
                :disabled="col.disabled"
                validate-on-blur
              ></v-combobox>
            </div>
            <v-text-field
              v-if="(col.type == 'text' || col.type == 'date') && col.ref != 'ssn'"
              color="selected"
              outlined
              dense
              :type="col.type"
              :id="col.id"
              :class="col.class"
              :label="col.label"
              v-model="formBindings[col.ref]"
              :rules="col.rules"
              :maxlength="col.maxlength"
              autocomplete="off"
              @blur="emailEntered(col.ref)"
              validate-on-blur
            ></v-text-field>
            <ssn-input
              v-if="col.ref == 'ssn'"
              :rules="col.rules"
              :input="col"
              v-on:update="updateSSN"
            ></ssn-input>
            <div :id="col.ref" v-if="col.type == 'select'" style="width: 100%">
              <v-select
                color="white"
                background-color="info"
                class="select-directive regular-select"
                v-solo-select-error:[directiveArg(col.ref)]="testRules(col.rules, col.ref)"
                dense
                solo
                :menu-props="{ bottom: true,  offsetY: true }"
                :label="col.label"
                :items="col.items"
                v-model="formBindings[col.ref]"
                :rules="col.rules"
                validate-on-blur
              ></v-select>
            </div>
            <div v-if="col.under40" class="d-flex">
              <div>
                <label class="ml-7" style="position: relative; bottom: 4px;">{{ text.under40 }}</label>
                <v-checkbox
                  color="secondary"
                  class="d-inline-block ml-3"
                  v-model="under40"
                  @change="under40Change"
                >
                </v-checkbox>
              </div>
              <div class="d-inline-block ml-16 mt-n1" v-if="under40">
                <label class="required">{{ text.dob }}</label>
                <v-text-field
                  color="selected"
                  type="date"
                  dense
                  placeholder="mm/dd/yyyy"
                  class="d-inline-block ml-5"
                  v-model="formBindings.dob"
                  :rules="dobRules"
                  validate-on-blur
                ></v-text-field>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <hr class="divider">
          </v-col>
        </v-row>
        <v-row class="mb-3">
          <v-col cols="5" offset="1">
            <span class="text-h4">{{ text.sqTitle }}</span>
          </v-col>
        </v-row>
        <!-- <span v-for="item in inputs.surveyQuestions" v-if="item.ref == 'surveyQ1' || (formBindings['surveyQ1'] ? formBindings['surveyQ1'] == 'no' : true)" :key="item.ref"> -->
        <span v-for="i in inputs.surveyQsLength" v-if="!!(i - 1) || extendedForm" :key="`surveyQ-${i - 1}`">
          <v-row>
            <v-col cols="4" offset="1">
              <label :class="(formBindings.surveyQuestions[i - 1].val == undefined && submitted ? 'sq-label-error ' : '') + 'required'">
                {{ getSurveyQuestionLabel(i - 1) }}
              </label>
            </v-col>
            <v-col>
              <v-radio-group v-model="formBindings.surveyQuestions[i - 1].val" :rules="inputs.surveyQuestionsRules" dense :id="`survey-question-${i}`">
                <v-radio
                  color="selected"
                  :key="`surveyQ-${i - 1}-yes`"
                  :label="text.y"
                  :ripple="false"
                  :value="true"
                ></v-radio>
                <v-radio
                  color="selected"
                  :key="`surveyQ-${i - 1}-no`"
                  :ripple="false"
                  :label="text.n"
                  :value="false"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row
            v-if="extendedForm && !!text.surveyQuestionsExtended[i - 1].followups && formBindings.surveyQuestions[i - 1].val"
            :style="`margin-top: ${text.surveyQuestionsExtended[i - 1].marginBottom}px`"
          >
            <v-col cols="12" offset="1">
              <v-icon
                large
                color="secondary"
                class="mt-n1 mb-1"
                v-if="text.surveyQuestionsExtended[i - 1].followupsHeader"
              >
                mdi-arrow-down-thin
              </v-icon>
              <div
                class="followupsHeader mb-4 font-italic"
                v-if="text.surveyQuestionsExtended[i - 1].followupsHeader"
              >
                {{ text.surveyQuestionsExtended[i - 1].followupsHeader }}
              </div>
              <div v-for="(question, j) in text.surveyQuestionsExtended[i - 1].followups">
                <div class="d-flex">
                  <template v-if="question.showIfOption ? formBindings.surveyQuestions[i - 1].followups[question.showIfOption.questionIndex].val == question.showIfOption.option : true">
                    <label :for="question.text" class="surveyQ-followup required">{{ question.text }}</label>
                    <v-select
                      v-if="question.type == 'select'"
                      color="selected"
                      :menu-props="{ bottom: true, offsetY: true }"
                      placeholder="Select"
                      class="mb-3 surveyQ-text-followup"
                      :items="question.options"
                      :rules="[inputs.rules.required]"
                      v-model="formBindings.surveyQuestions[i - 1].followups[j].val"
                      :id="question.text"
                    ></v-select>
                    <v-text-field
                      v-if="question.type == 'text'"
                      class="surveyQ-text-followup mb-3"
                      color="selected"
                      :id="question.text"
                      :rules="[inputs.rules.required]"
                      :type="question.date ? 'date' : 'text'"
                      v-model="formBindings.surveyQuestions[i - 1].followups[j].val"
                    ></v-text-field>
                    <v-radio-group
                      dense
                      row
                      class="surveyQ-binary-followup"
                      :rules="inputs.surveyQuestionsRules"
                      v-if="question.type == 'binary'"
                      v-model="formBindings.surveyQuestions[i - 1].followups[j].val"
                    >
                      <v-radio
                        color="selected"
                        :key="`${question.text}-yes`"
                        :label="text.y"
                        :ripple="false"
                        :value="true"
                      ></v-radio>
                      <v-radio
                        color="selected"
                        :key="`${question.text}-no`"
                        :ripple="false"
                        :label="text.n"
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                  </template>
                </div>
              </div>
            </v-col>
          </v-row>
        </span>
        <v-row>
          <v-col cols="12">
            <hr class="divider">
          </v-col>
        </v-row>
        <youth-questionnare
          v-show="youthQuestionnareShow"
          :lang="lang"
          :mobile="false"
          v-on:checked="setYouthQuestionnare"
        ></youth-questionnare>
        <div>
          <v-row>
            <v-col cols="7" offset="1">
              <span class="text-h4">{{ text.closing.title }}</span>
              <p class="font-italic mt-2">{{ text.closing.main }}</p>
              <span class="font-weight-medium">{{ text.closing.inputHeader }}</span>
            </v-col>
          </v-row>
          <v-row class="mt-n8">
            <v-col cols="2" offset="1" class="d-flex align-center text-no-wrap">
              <label class="required">{{ text.closing.ssn }}</label>
              <v-text-field
                color="selected"
                dense
                v-model="formBindings.signature.ssn"
                class="ml-5"
                maxlength="4"
                :rules="signatureSsnRules"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="d-flex align-center ml-10">
              <label>{{ text.closing.date }}</label>
              <v-text-field
                color="selected"
                dense
                class="ml-5"
                type="date"
                v-model="formBindings.signature.date"
                :maxlength="10"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <hr class="divider">
            </v-col>
          </v-row>
          <v-row class="submit-row">
            <v-col cols="12" offset="1" v-show="!loading">
              <v-btn color="success" type="submit">{{ text.button }}</v-btn>
              <span class="ml-3" style="font-size: 14px"><i>{{ text.record }}</i></span>
              <div class="font-italic mt-5 ml-1">{{ text.infoText }} <b>{{ clientName }}</b></div>
            </v-col>
            <v-col cols="10" offset="1" v-show="loading">
              <v-progress-linear color="success" indeterminate></v-progress-linear>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-card>
    <v-snackbar :value="!!formErr" color="error" class="d-none d-lg-flex">
      <div class="snackbar">{{ formErr }}</div>
    </v-snackbar>
  </div>
</template>

<script>
import ssnInput from '@/modules/shared/components/SSN'
import soloSelectError from '@/modules/shared/js/solo-select-error'
import youthQuestionnare from './../components/YouthQuestionnare'
import checkYouthQuestionnare from './../js/youth-questionnare'

export default {
	name: 'DesktopVersion',
	props: {
		inputs: Object,
		text: Object,
		lang: String,
    loading: Boolean,
    formBindings: Object,
    clientName: String
	},
	components: {
		ssnInput,
    youthQuestionnare
	},
	directives: {
		soloSelectError
	},
	created: function() {
		const locInput = this.inputs.fields[0].cols[1]
		if (locInput.items.length == 1) {
			this.inputs.fields[0].cols[1].disabled = true
			this.formBindings[locInput.ref] = locInput.items[0]
		}
	},
	data: function() {
		return {
			under40: false,
			labelColClass: 'd-flex align-center justify-end',
      youthQuestionnareShow: false,
      signatureSsnRules: [],
      formErr: null,
			submitted: false
		}
	},
	computed: {
		dobRules() {
			if (!this.submitted) return
			if (!this.inputs.isSafari) return [this.inputs.rules.required]
			return [this.inputs.rules.required]
		},
    signatureSsnError() {
      if (!this.formBindings.ssn) return false
      if (this.formBindings.ssn.slice(-4) != this.formBindings.signature.ssn) return true
      return false
    },
    extendedForm() {
      // for now, all forms should be the extended version
      return true
      // return this.$router.history.current.query.extended == 'true'
    }
	},
	watch: {
		'formBindings.dob'() {
      this.setYouthQuestionnareShow()
			
		},
		'formBindings.state'() {
      this.setYouthQuestionnareShow()
		},
    // We are not showing surveyQ1 for now
		// 'formBindings.surveyQ1'() {
		// 	if (this.submitted) this.validate()
		// }
	},
	methods: {
    setYouthQuestionnareShow() {
      this.youthQuestionnareShow = checkYouthQuestionnare({
        dob: this.formBindings.dob,
        state: this.formBindings.state
      })
      if (this.youthQuestionnareShow) this.formBindings.youthQuestionnareChecked = true
    },
    changeLang() {
      this.$emit('changeLang')
    },
    submit() {
      this.submitted = true
      this.signatureSsnRules = [this.inputs.rules.required, () => !this.signatureSsnError || this.text.errors.finalSSN]
      this.formErr = null
      this.$nextTick(() => {
        const valid = this.$refs.form.validate()
        if (valid) {
          this.$emit('setLoading', true)
          this.formBindings.under40 = this.under40
          this.$emit('uploadForm')
        } else {
          setTimeout(() => this.formErr = this.text.errors.fix)
          this.$emit('setLoading', false)
          for (const i in this.$refs.form.$children) {
            // not all children are inputs so put inside a try-catch
            try {
              if (!this.$refs.form.$children[i].validate()) {
                if (this.$refs.form.$children[i].$refs.input) {
                  this.scrollToElem(this.$refs.form.$children[i].$refs.input)
                } else {
                  this.scrollToElem(this.$refs.form.$children[i].$refs['input-slot'].children[0])
                }
                break
              }
            } catch(err) { }
          }
        }
      })
    },
    scrollToElem(el) {
      const targetPosition = el.getBoundingClientRect().top + window.pageYOffset - 125
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth'
      })
    },
    setYouthQuestionnare(event) {
    	this.formBindings.youthQuestionnareChecked = event
    },
    validate() {
      this.$nextTick(() => this.$refs.form.validate())
    },
		directiveArg(id) {
			return {
				customSelect: false,
				firstSubmit: this.submitted,
        formBindings: this.formBindings,
				id
			}
		},
		testRules(rules, ref) {
      return function(formBindings) {
        let err = false
        for (const x in rules) {
          if (rules[x](formBindings[ref]) !== true) err = true
        }

        return err
      }
		},
		wrongNumber() {
			this.$router.push({ name: 'Access Form', query: this.$router.history.current.query })
		},
		getColClass(col) {
			if (col.wrongNumberBtn || col.under40) return ''
			return `${this.labelColClass} ${!!col.class ? col.class : ''}`
		},
		calcCols(cols) {
			if (!cols) return
			if (!cols.includes('-')) return cols
			if (this.lang == 'en') return cols.split('-')[0]
			return cols.split('-')[1]
		},
		getLabelClass(col) {
			return (col.required ? 'required' : '') + ` ${col.labelClass || ''}`
		},
		getLabelStyle(style) {
			if (!style) return null
			if (this.lang == 'en') return style.split('$')[0]
			return style.split('$')[1]
		},
		emailEntered(ref) {
			if (ref != 'email' && ref != 'phoneNumber') return
			// can send multiple emails if you re-enter email eaddress
			if ((/^\S+@\S+\.\S+$/.test(this.formBindings.email))) this.$emit('trySendReminder', {
				email: this.formBindings.email,
				phoneNumber: this.formBindings.phoneNumber,
				name: this.formBindings.firstName ? `${this.formBindings.firstName} ${this.formBindings.lastName}` : null
			})
		},
		under40Change(val) {
			if (!val) this.formBindings.dob = null
		},
		updateSSN(val) {
			this.formBindings.ssn = val
		},
    getSurveyQuestionLabel(index) {
      if (!this.extendedForm) {
        return this.text.surveyQuestions[index]
      } else {
        return this.text.surveyQuestionsExtended[index].question
      }
    }
	}
}
</script>