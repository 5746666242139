<template>
	<div class="d-flex align-center justify-center card-center-wrapper" v-if="data">
		<v-card elevation="5" class="d-flex flex-column align-center justify-center rounded-lg pa-2">
			<div class="sub-wrapper">
				<div class="text-h4 text-md-h2 text-center title" id="success">{{ text.title }}</div>
				<v-alert type="success" :icon="false" elevation="4" class="mt-3 mt-md-7 pa-10 rounded-lg">
					{{ text.p1 }} {{ data.name }} {{ text.p2 }} {{ formType }} {{ text.p3 }} <b class="dark">{{ data.uid }}</b>. {{ text.p4 }}
				</v-alert>
			</div>
			<div class="mt-md-5 text-center">
				<v-btn color="selected" class="mr-md-5 mb-md-0 mb-2 text-capitalize" :to="{ name: 'Tax Credit Screening', query: { lang, number: data.number, extended: data.extended } }" style="color: white">screen another employee</v-btn>
				<br class="d-md-none">
        <v-btn color="primary" class="text-capitalize" to="/">Homepage</v-btn>
			</div>
		</v-card>
	</div>
</template>

<script>
import { successMessage, title } from './../js/text'

export default {
	name: 'SuccessMessage',
	created: function() {
		this.data = this.$route.query
	},
	data: function() {
		return {
			data: null
		}
	},
	computed: {
		lang() {
			let lang = this.data.lang
			if (lang != 'en' && lang != 'sp') lang = 'en'
			return lang
		},
		text() {
			return successMessage[this.lang]
		},
		formType() {
			return title[this.lang]
		}
	}
}
</script>